import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

import { PortalHttpService } from './portal.http.service';
import { RetrieveCountriesResponse } from '../schema/generated/RetrieveCountriesResponse';
import { RetrieveCountryCodesResponse } from '../schema/generated/RetrieveCountryCodesResponse';
import { RetrieveEducationLevelsResponse } from '../schema/generated/RetrieveEducationLevelsResponse';
import { RetrieveLegalFormsResponse } from '../schema/generated/RetrieveLegalFormsResponse';
import { RetrieveNationalitiesResponse } from '../schema/generated/RetrieveNationalitiesResponse';
import { AuthService } from '~/v2/core/services/auth.service';
import { CacheService } from '~/v2/core/services/cache.service';
import { AlertService } from '~/v2/shared/components/alert/alert.service';

@Injectable({
  providedIn: 'root',
})
export class MasterDataService extends PortalHttpService {
  constructor(
    http: HttpClient,
    authService: AuthService,
    cacheService: CacheService,
    alertService: AlertService,
    languageService: TranslateService,
  ) {
    super(http, alertService, authService, cacheService, languageService);
  }

  retrieveCountries(): Observable<any> {
    return super.get<RetrieveCountriesResponse>(`web/v1/masterdata/countries`);
  }

  retrieveCountryCodes(): Observable<any> {
    return super.get<RetrieveCountryCodesResponse>(`web/v1/masterdata/countrycodes`);
  }

  retrieveNationalities(): Observable<any> {
    return super.get<RetrieveNationalitiesResponse>(`web/v1/masterdata/nationalities`);
  }

  retrieveLanguages(): Observable<any> {
    return super.get<any[]>(`web/v1/masterdata/languages`);
  }

  retrieveLegalForms(): Observable<any> {
    return super.get<RetrieveLegalFormsResponse>(`web/v1/masterdata/legalforms`);
  }

  retrieveEducationLevels(): Observable<any> {
    return super.get<RetrieveEducationLevelsResponse>(`web/v1/masterdata/educationlevels`);
  }

  retrieveBillingPeriods(): Observable<any> {
    return super.get<any>(`web/v1/masterdata/billingperiods`);
  }

  retrieveRegexes(): Observable<any> {
    return super.get(`web/v1/masterdata/regexes`);
  }
}
