import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ConsoleLogErrorHandler } from '~/v2/core/error-handling/console-log.error-handler';
import { AlertService } from '~/v2/shared/components/alert/alert.service';

export abstract class HttpService {
  wellknownErrorCodes: any[] | null = null;

  protected httpHeaders: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
  });
  protected http: HttpClient;
  protected alertService: AlertService;

  constructor(http: HttpClient, alertService: AlertService) {
    this.http = http;
    this.alertService = alertService;
  }

  private retryOnceWhen401<T>(request: Observable<T>, errorHandler: any = null) {
    const handler = errorHandler === null ? this.handleError : errorHandler;

    return request.pipe(
      catchError((err) => {
        if (err.status == 401) {
          return request.pipe(catchError(handler));
        }
        return handler(err);
      }),
    );
  }

  protected get<T>(url: string, errorHandler: any = null): Observable<any> {
    const request = this.http.get<T>(url, { headers: this.httpHeaders });
    return this.retryOnceWhen401(request, errorHandler);
  }

  protected post<T>(url: string, object?: any, errorHandler: any = null): Observable<any> {
    const request = this.http.post<T>(url, object, {
      headers: this.httpHeaders,
    });

    return this.retryOnceWhen401(request, errorHandler);
  }

  protected put<T>(url: string, object: any, errorHandler: any = null): Observable<any> {
    const request = this.http.put<T>(url, object, {
      headers: this.httpHeaders,
    });

    return this.retryOnceWhen401(request, errorHandler);
  }

  protected delete<T>(url: string, errorHandler: any = null): Observable<any> {
    const request = this.http.delete<T>(url);
    return this.retryOnceWhen401(request, errorHandler);
  }

  protected handleError(err: any): Observable<never> {
    return ConsoleLogErrorHandler.handleError(err);
  }
}
