export class SystemContext {
  private static delimeter = ':';

  constructor(
    public discriminator: string,
    public identifier: string,
  ) {}

  static deserialize(serialized: string): SystemContext {
    const parts = serialized.split(this.delimeter);
    return this.construct(parts);
  }

  static Empty(discriminator: string): SystemContext {
    return new SystemContext(discriminator, '');
  }

  private static construct(parts: string[]): SystemContext {
    const discriminator = parts[0];
    const identifier = parts[1];
    return new SystemContext(discriminator, identifier);
  }
}
