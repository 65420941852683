import { firstValueFrom, takeUntil, tap, timer, map, take } from 'rxjs';
import { APP_INITIALIZER, Injectable, Provider } from '@angular/core';

import { MasterDataService } from '~/v2/data/service/master-data.service';

@Injectable({
  providedIn: 'root',
})
export class RegexService {
  private dataItems: { value: string }[] = [];

  constructor(private masterDataService: MasterDataService) {}

  getPattern(pattern: number): string {
    return this.dataItems[pattern - 1].value;
  }

  async init(): Promise<void> {
    const { masterDataService } = this;
    const regexString = localStorage.getItem(REGEXES_LOCAL_STORAGE_KEY);

    if (regexString) {
      this.dataItems = JSON.parse(regexString);
    } else {
      this.dataItems = await firstValueFrom(
        masterDataService.retrieveRegexes().pipe(
          take(1),
          map((data) => {
            const mappedData: any = [];

            Object.keys(data.value).map((element) => {
              const value = data.value[element];
              mappedData.push({ key: element, value: value });
            });

            localStorage.setItem(REGEXES_LOCAL_STORAGE_KEY, JSON.stringify(mappedData));

            return mappedData;
          }),
        ),
      );
    }
  }
}

const REGEXES_LOCAL_STORAGE_KEY = 'regexes';
