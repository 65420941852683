import { IAuthorizationRequirement } from './authorizationrequirement';

export class AuthorizationResult {
  success = false;
  failedRequirements: IAuthorizationRequirement[] = [];

  constructor() {}

  static Success(): AuthorizationResult {
    return { success: true } as AuthorizationResult;
  }

  static Failed(requirements: IAuthorizationRequirement[]): AuthorizationResult {
    return { success: false, failedRequirements: requirements } as AuthorizationResult;
  }
}
