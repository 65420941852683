import { Guid } from 'guid-typescript';

import { MemberIdentity } from './memberidentity';
import '../../extensions/array';
import { LocalStorage } from '~/v2/core/helpers/localStorage';
import { UserType } from '~/v2/data/schema/userType';

export class BrainnetPrincipal {
  private readonly memberStorageKey: string = 'activemember';

  displayName(): string {
    return this.name != null ? this.name : '~logged in person~';
  }

  claims: any;
  name = '';
  initials = '';
  language = '';
  identifier = '';
  isFirstTimeLogin = true;
  memberIdentities: MemberIdentity[] = [];
  activeMember: MemberIdentity = new MemberIdentity();

  constructor(claims: any) {
    this.identifier = claims?.sub;
    this.name = claims?.name;
    this.initials = claims?.initials;
    this.language = claims?.languageCode;
    this.isFirstTimeLogin = claims?.isFirstTimeLogin;
    this.claims = claims;
    this.activeMember.identifier = Guid.createEmpty();
    this.loadMemberIdentities(claims);
  }

  private loadMemberIdentities(claims: any) {
    const members = claims.member_identities as string[];
    this.memberIdentities = members.deserialize((_) => MemberIdentity.deserialize(_));
    const memberIdentifier = LocalStorage.Guid(this.memberStorageKey);
    this.setActiveMember(memberIdentifier);
  }

  getUserType(): UserType {
    return Number(this.activeMember?.userType);
  }

  getProfessionalId(): string {
    return this.activeMember?.professionalId;
  }

  setActiveMember(memberIdentity: Guid): void {
    for (const member of this.memberIdentities) {
      if (member.identifier.toString() === memberIdentity.toString()) {
        this.activeMember = member;
        LocalStorage.setGuid(this.memberStorageKey, memberIdentity);
        return;
      }
    }
    const firstIdentity = this.memberIdentities[0];
    if (firstIdentity) {
      const firstMember = firstIdentity.identifier;
      this.setActiveMember(firstMember);
    }
  }
}
