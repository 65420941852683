import { Inject, Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';

import { AuthorizationContext } from '../security/authorizationcontext';
import { IAuthorizationHandler } from '../security/authorizationhandler';
import { AuthorizationPolicyProvider } from '../security/authorizationpolicyprovider';
import { AuthorizationResult } from '../security/authorizationresult';
import { AuthorizationPolicy } from '../security/models/authorizationpolicy';
import { CompanyContext } from '../security/models/companycontext';
import { AUTH_HANDLERS } from '../security/security.module';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthPolicyService {
  constructor(
    private auth: AuthService,
    private provider: AuthorizationPolicyProvider,
    @Inject(AUTH_HANDLERS) private handlers: IAuthorizationHandler[],
  ) {}

  authorize(policy: string, obj: any = null): AuthorizationResult {
    const principal = this.auth.principal;

    let authorizationPolicy = this.provider.get(policy);
    if (authorizationPolicy == undefined) {
      authorizationPolicy = new AuthorizationPolicy([]);
    }

    const context = new AuthorizationContext(authorizationPolicy!.requirements, principal!, obj);
    this.handlers.forEach((handler) => {
      handler.handle(context);
    });
    return context.hasSucceeded()!
      ? AuthorizationResult.Success()
      : AuthorizationResult.Failed(context.failedRequirements);
  }

  authorizeAgainst(policy: string, companyIdentifier: Guid): AuthorizationResult {
    const companyContext: CompanyContext = {
      CompanyIdentifier: companyIdentifier,
    };
    return this.authorize(policy, companyContext);
  }
}
