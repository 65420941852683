import { AuthorizationContext } from './authorizationcontext';
import { IAuthorizationRequirement } from './authorizationrequirement';

export interface IAuthorizationHandler {
  handle(context: AuthorizationContext): void;
}

export abstract class AuthorizationHandler<TIAuthorizationRequirement> implements IAuthorizationHandler {
  protected requirementType: string;

  constructor(requirementType: string) {
    this.requirementType = requirementType;
  }

  handle(context: AuthorizationContext): void {
    context.requirements.forEach((requirement) => {
      if (requirement.name === this.requirementType) {
        this.onHandle(context, requirement);
      }
    });
  }

  abstract onHandle(context: AuthorizationContext, requirement: IAuthorizationRequirement): void;
}
