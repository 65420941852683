export class GlobalConstants {
  static languageLocalStorageItemKey = 'language';
  static introCompletedCookieKey = 'intro_completed';
  static tutorialCompletedKey = 'tutorial_completed';
  static momentShortDateFormat = 'yyyy-MM-DD';
  static angularShortDateFormat = 'yyyy-MM-dd';
  static invoiceNumberMaxLength = 22;
  static dutchCountryNames: string[] = ['Nederland', 'Netherlands'];
  static privacyAndCookieStatementLink = 'https://magnitglobal.com/nl/privacy-notice.html';
}
