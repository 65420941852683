import { Injectable } from '@angular/core';

import { AuthorizationPolicyBuilder } from './authorizationpolicybuilder';
import { AuthorizationPolicy } from './models/authorizationpolicy';
import { WellKnownPolicies } from './wellknownpolicies';
import { WellKnownScopes } from './wellknownscopes';
import { Permission } from './models/permission';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationPolicyProvider {
  policyMap: Map<string, AuthorizationPolicy> = new Map<string, AuthorizationPolicy>();

  constructor() {
    this.add(WellKnownPolicies.signContractWorkorder, (builder) => {
      builder
        .RequireAuthenticatedUser()
        .RequireScopePermission(WellKnownScopes.contractWorkorderSigning, Permission.manage);
    });

    this.add(WellKnownPolicies.signContractServiceAgreement, (builder) => {
      builder
        .RequireAuthenticatedUser()
        .RequireScopePermission(WellKnownScopes.contractServiceAgreementSigning, Permission.manage);
    });

    this.add(WellKnownPolicies.lookAtJobrequests, (builder) => {
      builder.RequireAuthenticatedUser().RequireScopePermission(WellKnownScopes.jobrequest, Permission.read);
    });

    this.add(WellKnownPolicies.manageJobrequests, (builder) => {
      builder.RequireAuthenticatedUser().RequireScopePermission(WellKnownScopes.jobrequest, Permission.manage);
    });

    this.add(WellKnownPolicies.lookAtScreenings, (builder) => {
      builder.RequireAuthenticatedUser().RequireScopePermission(WellKnownScopes.screening, Permission.read);
    });

    this.add(WellKnownPolicies.lookAtAllScreenings, (builder) => {
      builder.RequireAuthenticatedUser().RequireScopePermission(WellKnownScopes.screeningAll, Permission.read);
    });

    this.add(WellKnownPolicies.manageAllScreenings, (builder) => {
      builder.RequireAuthenticatedUser().RequireScopePermission(WellKnownScopes.screeningAll, Permission.manage);
    });

    this.add(WellKnownPolicies.lookAtContracts, (builder) => {
      builder.RequireAuthenticatedUser().RequireScopePermission(WellKnownScopes.contract, Permission.read);
    });

    this.add(WellKnownPolicies.lookAtFinancials, (builder) => {
      builder.RequireAuthenticatedUser().RequireScopePermission(WellKnownScopes.financial, Permission.read);
    });

    this.add(WellKnownPolicies.lookAtAssignments, (builder) => {
      builder.RequireAuthenticatedUser().RequireAssignmentScopePermission();
    });

    this.add(WellKnownPolicies.approvePaymentProposals, (builder) => {
      builder
        .RequireAuthenticatedUser()
        .RequireScopePermission(WellKnownScopes.paymentproposalApproval, Permission.manage);
    });

    this.add(WellKnownPolicies.manageCompanyDetails, (builder) => {
      builder.RequireAuthenticatedUser().RequireScopePermission(WellKnownScopes.companyDetail, Permission.manage);
    });
  }

  private add(key: string, policyBuilder: (builder: AuthorizationPolicyBuilder) => void): void {
    const alreadyExisting = this.policyMap.has(key);
    if (!alreadyExisting) {
      const builder = new AuthorizationPolicyBuilder();
      policyBuilder(builder);
      const policy = builder.build();
      this.policyMap.set(key, policy);
    }
  }

  get(key: string): AuthorizationPolicy | undefined {
    return this.policyMap.get(key);
  }
}
