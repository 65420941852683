import { AuthorizationContext } from '../authorizationcontext';
import { AuthorizationHandler } from '../authorizationhandler';
import { ClaimRequirement } from '../requirements/claim.requirements';

export class ClaimsHandler extends AuthorizationHandler<ClaimRequirement> {
  constructor() {
    super(`ClaimRequirement`);
  }

  onHandle(context: AuthorizationContext, requirement: ClaimRequirement): void {
    if (context.principal == null) {
      return;
    }

    const requiredValues = requirement.allowedValues;
    const userClaims = context.principal.claims;

    const claimExists = ClaimsHandler.claimExists(userClaims, requirement.claimType);
    if (!claimExists) {
      context.fail(requirement);
    } else if (requiredValues == null || requiredValues.length == 0) {
      context.succeed(requirement);
    } else {
      const isAllowed = ClaimsHandler.hasAllowedValues(requiredValues, userClaims[requirement.claimType]);
      isAllowed == true ? context.succeed(requirement) : context.fail(requirement);
    }
  }

  private static claimExists(claims: any, claimType: string): boolean {
    for (const key in Object.keys(claims)) {
      if (key.toLocaleLowerCase() == claimType.toLocaleLowerCase()) {
        return true;
      }
    }
    return false;
  }

  private static hasAllowedValues(allowedValues: string[], claimValue: any): boolean {
    for (const allowedValue in allowedValues) {
      if (allowedValue == claimValue) {
        return true;
      }
    }
    return false;
  }
}
