import { Guid } from 'guid-typescript';

export class LocalStorage {
  static Guid(key: string): Guid {
    const value = localStorage.getItem(key);
    if (value) {
      return Guid.parse(value);
    }
    return Guid.parse(Guid.EMPTY);
  }

  static setGuid(key: string, guid: Guid): void {
    localStorage.setItem(key, guid.toString());
  }
}
