export enum UserType {
  Unspecified = 0,
  CustomerContact = 1,
  SelfEmployedProfessional = 2,
  SupplierContact = 3,
  Professional = 4,
}
export function userTypeAware(constructor: Function) {
  constructor.prototype.UserType = UserType;
}
