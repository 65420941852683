import { IAuthorizationRequirement } from '../authorizationrequirement';
import { ScopePermission } from '../models/scopepermission';

export class ScopePermissionRequirement implements IAuthorizationRequirement {
  scopePermission: ScopePermission;
  name: string;

  constructor(scopePermission: ScopePermission) {
    this.scopePermission = scopePermission;
    this.name = `ScopePermissionRequirement`;
  }
}
