import { IAuthorizationRequirement } from './authorizationrequirement';
import { BrainnetPrincipal } from './models/brainnetprincipal';

export class AuthorizationContext {
  principal: BrainnetPrincipal;
  requirements: IAuthorizationRequirement[] = [];
  failedRequirements: IAuthorizationRequirement[] = [];
  object: any;
  private pendingRequirements: IAuthorizationRequirement[] = [];

  constructor(requirements: IAuthorizationRequirement[], principal: BrainnetPrincipal, object: any = null) {
    this.requirements = requirements;
    this.principal = principal;
    this.object = object;
    requirements.forEach((x) => this.pendingRequirements.push(x));
  }

  hasSucceeded(): boolean {
    return this.failedRequirements.length == 0;
  }

  succeed(requirement: IAuthorizationRequirement): void {
    this.removeRequirement(requirement);
  }

  fail(requirement: IAuthorizationRequirement): void {
    this.removeRequirement(requirement);
    this.failedRequirements.push(requirement);
  }

  private removeRequirement(requirement: IAuthorizationRequirement): void {
    const index = this.pendingRequirements.indexOf(requirement);
    this.pendingRequirements.splice(index, 1);
  }
}
