import { AuthorizationContext } from '../authorizationcontext';
import { AuthorizationHandler } from '../authorizationhandler';
import { CompanyContext } from '../models/companycontext';
import { Permission } from '../models/permission';
import { ScopePermission } from '../models/scopepermission';
import { AssignmentScopePermissionRequirement } from '../requirements/assignmentscopepermission.requirement';

export class AssignmentScopePermissionHandler extends AuthorizationHandler<AssignmentScopePermissionRequirement> {
  constructor() {
    super(`AssignmentScopePermissionRequirement`);
  }

  onHandle(context: AuthorizationContext, requirement: AssignmentScopePermissionRequirement): void {
    if (context.principal == null) {
      return;
    }

    let companies = context.principal.activeMember.companies;
    if (context.object != null && context.object instanceof CompanyContext) {
      const companyContext = context.object as CompanyContext;
      const company = companies.find((x) => x.identifier.equals(companyContext.CompanyIdentifier));
      companies = [company!];
    }

    const assignmentScope = requirement.assignmentScopePermission.scope;
    const assignmentPermission = requirement.assignmentScopePermission.permission;

    const assignmentsAllScope = requirement.assignmentsAllScopePermission.scope;
    const assignmentsAllPermission = requirement.assignmentsAllScopePermission.permission;

    const userScopePermissions: ScopePermission[] = [];
    companies.forEach((x) => {
      x.scopePermissions.forEach((y) => {
        userScopePermissions.push(y);
      });
    });

    const hasAssignmentScopePermission = userScopePermissions.some(
      (sp) =>
        sp.scope.toLocaleLowerCase() == assignmentScope &&
        (sp.permission == assignmentPermission || sp.permission == Permission.manage),
    );
    const hasAssignmentsAllScopePermission = userScopePermissions.some(
      (sp) =>
        sp.scope.toLocaleLowerCase() == assignmentsAllScope &&
        (sp.permission == assignmentsAllPermission || sp.permission == Permission.manage),
    );

    if (hasAssignmentScopePermission || hasAssignmentsAllScopePermission) {
      context.succeed(requirement);
      return;
    } else {
      context.fail(requirement);
      return;
    }
  }
}
