import { HttpClient } from '@angular/common/http';
import { Observable, finalize } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { HttpService } from './http.service';
import { environment } from '@env';
import { ConsoleLogErrorHandler } from '~/v2/core/error-handling/console-log.error-handler';
import { AuthService } from '~/v2/core/services/auth.service';
import { CacheService } from '~/v2/core/services/cache.service';
import { AlertService } from '~/v2/shared/components/alert/alert.service';

export abstract class PortalHttpService extends HttpService {
  constructor(
    http: HttpClient,
    alertService: AlertService,
    public auth: AuthService,
    private cacheService: CacheService,
    private languageService: TranslateService,
    private apiBaseUrl = environment.apiBaseUrl,
  ) {
    super(http, alertService);
  }

  protected override get<T = any>(urlSegment: string, errorHandler: any = null): Observable<T> {
    const handler = errorHandler === null ? this.handleError.bind(this) : errorHandler;
    const uri = `${this.apiBaseUrl}/${urlSegment}`;
    this.addHttpHeaders();
    return super.get<T>(uri, handler);
  }

  protected override post<T>(urlSegment: string, body?: any, errorHandler: any = null): Observable<any> {
    const handler = errorHandler === null ? this.handleError.bind(this) : errorHandler;
    const uri = `${this.apiBaseUrl}/${urlSegment}`;
    this.addHttpHeaders();
    return super.post<T>(uri, body, handler).pipe(this.loadingIndicator());
  }

  private loadingIndicator() {
    document.body.classList.add('portal-is-loading');
    return finalize(() => {
      document.body.classList.remove('portal-is-loading');
    });
  }

  protected override put<T>(urlSegment: string, body?: any, errorHandler: any = null): Observable<any> {
    const handler = errorHandler === null ? this.handleError.bind(this) : errorHandler;
    const uri = `${this.apiBaseUrl}/${urlSegment}`;
    this.addHttpHeaders();
    return super.put<T>(uri, body, handler).pipe(this.loadingIndicator());
  }

  protected override delete<T>(urlSegment: string, errorHandler: any = null): Observable<any> {
    const handler = errorHandler === null ? this.handleError.bind(this) : errorHandler;
    const uri = `${this.apiBaseUrl}/${urlSegment}`;
    this.addHttpHeaders();
    return super.delete<T>(uri, handler);
  }

  protected override handleError(err: any): Observable<never> {
    if (err.status == 401) {
      this.alertService.error('common.unauthorised');
    } else if (err.status == 403) {
      this.auth.returnToHome();
    } else if (err.error instanceof Array) {
      const errors: { code: string; description: string }[] = err.error;
      for (const item of errors) {
        this.alertService.error(item.code);
      }
    } else {
      this.alertService.error('common.something-went-wrong-try-again-or-contact-us');
    }
    return ConsoleLogErrorHandler.handleError(err);
  }

  private addHttpHeaders() {
    this.setActiveMemberHeader();
    this.setOperationIdHeader();
    this.setLanguageHeader();
  }

  private addHeaderValue(name: string, value: any): void {
    if (!this.httpHeaders.has(name)) {
      this.httpHeaders = this.httpHeaders.append(name, value);
    } else if (this.httpHeaders.get(name) !== value) {
      this.httpHeaders = this.httpHeaders.set(name, value);
    }
  }

  private setActiveMemberHeader(): void {
    const headerName = 'x-active-member';
    const principal = this.auth.principal;
    if (principal) {
      const memberIdentifier = principal.activeMember.identifier.toString();
      this.addHeaderValue(headerName, memberIdentifier);
    }
  }

  private setOperationIdHeader(): void {
    const headerName = 'X-Operation-Id';
    const operationId = this.cacheService.getOperationId();

    this.addHeaderValue(headerName, operationId);
  }

  private setLanguageHeader(): void {
    const headerName = 'x-language-locale';
    const language = this.languageService.currentLang;

    this.addHeaderValue(headerName, language);
  }
}
