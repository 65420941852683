export class WellKnownPolicies {
  static signContractWorkorder = 'signContractWorkorder';
  static signContractServiceAgreement = 'signContractServiceAgreement';
  static manageJobrequests = 'manageJobrequests';
  static lookAtJobrequests = 'lookAtJobrequests';
  static lookAtScreenings = 'lookAtScreenings';
  static lookAtAllScreenings = 'lookAtAllScreenings';
  static manageAllScreenings = 'manageAllScreenings';
  static lookAtContracts = 'lookAtContracts';
  static lookAtFinancials = 'lookAtFinancials';
  static approvePaymentProposals = 'approvePaymentProposals';
  static manageCompanyDetails = 'manageCompanyDetails';
  static lookAtAssignments = 'lookAtAssignments';
  static lookAtAllAssignments = 'lookAtAllAssignments';
}
