export class WellKnownScopes {
  static contractWorkorderSigning = 'contract.workorder.sign';
  static contractServiceAgreementSigning = 'contract.service-agreement.sign';
  static jobrequest = 'jobrequest';
  static screening = 'screening';
  static screeningAll = 'screening.all';
  static contract = 'contract';
  static financial = 'financial';
  static paymentproposalApproval = 'financial.payment-proposals.approve';
  static companyDetail = 'company-detail';
  static assignment = 'assignment';
  static assignmentsAll = 'assignments.all';
}
