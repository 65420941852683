import { SystemContext } from '../security/models/systemcontext';

type ObjectDeserializer<Type> = (obj: string) => Type;
declare global {
  interface Array<T> {
    deserialize<Type>(deserializer: ObjectDeserializer<Type>): Type[];
    findContext(discriminator: string): SystemContext;
  }
}
Array.prototype.deserialize = function <Type>(deserializer: ObjectDeserializer<Type>): Type[] {
  const objects: Type[] = [];
  if (this != null) {
    this.forEach((_) => {
      const obj = deserializer(_);
      objects.push(obj);
    });
  }
  return objects;
};
Array.prototype.findContext = function (discriminator: string): SystemContext {
  const obj = this.find((x) => x.discriminator === discriminator);
  return obj ?? SystemContext.Empty(discriminator);
};
export {};
