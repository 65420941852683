import { Pipe, PipeTransform } from '@angular/core';
import { Guid } from 'guid-typescript';

@Pipe({
  name: 'guidPipe',
  standalone: true,
})
export class GuidPipe implements PipeTransform {
  transform(rawString: string): Guid {
    const formattedString = rawString.toLowerCase().replace(/(.{8})(.{4})(.{4})(.{4})(.{12})/, '$1-$2-$3-$4-$5');
    const testResult = Guid.parse(formattedString);

    return testResult;
  }
}
