import { AuthorizationContext } from '../authorizationcontext';
import { AuthorizationHandler } from '../authorizationhandler';
import { CompanyContext } from '../models/companycontext';
import { Permission } from '../models/permission';
import { ScopePermission } from '../models/scopepermission';
import { ScopePermissionRequirement } from '../requirements/scopepermission.requirement';

export class ScopePermissionHandler extends AuthorizationHandler<ScopePermissionRequirement> {
  private managePermission = 2;

  constructor() {
    super(`ScopePermissionRequirement`);
  }

  onHandle(context: AuthorizationContext, requirement: ScopePermissionRequirement): void {
    if (context.principal == null) {
      return;
    }

    let companies = context.principal.activeMember.companies;
    if (context.object != null && context.object instanceof CompanyContext) {
      const companyContext = context.object as CompanyContext;
      const company = companies.find((x) => x.identifier.equals(companyContext.CompanyIdentifier));
      companies = [company!];
    }

    const requiredScope = requirement.scopePermission.scope.toLocaleLowerCase();
    const requiredPermission = requirement.scopePermission.permission;
    const userScopePermissions: ScopePermission[] = [];
    companies.forEach((x) => {
      x.scopePermissions.forEach((y) => {
        userScopePermissions.push(y);
      });
    });

    const userHasScopePermission = userScopePermissions.some(
      (sp) => sp.scope.toLocaleLowerCase() == requiredScope && sp.permission == requiredPermission,
    );
    const userHasScopeManagePermission = userScopePermissions.some(
      (sp) => sp.scope.toLocaleLowerCase() == requiredScope && sp.permission == this.managePermission,
    );

    if (!userHasScopePermission) {
      context.fail(requirement);
      return;
    } else if (userHasScopePermission) {
      context.succeed(requirement);
      return;
    } else if (userHasScopeManagePermission && requiredPermission == Permission.read) {
      //Manage permissions supersedes Read permissions because if you can manage something you can also see it.
      context.succeed(requirement);
      return;
    }
    context.fail(requirement);
  }
}
