import { Injectable } from '@angular/core';

import { IAuthorizationRequirement } from './authorizationrequirement';
import { AuthorizationPolicy } from './models/authorizationpolicy';
import { Permission } from './models/permission';
import { ScopePermission } from './models/scopepermission';
import { AssignmentScopePermissionRequirement } from './requirements/assignmentscopepermission.requirement';
import { DenyAnonymousRequirement } from './requirements/denyanonymous.requirement';
import { ScopePermissionRequirement } from './requirements/scopepermission.requirement';
import { WellKnownScopes } from './wellknownscopes';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationPolicyBuilder {
  requirements: IAuthorizationRequirement[] = [];

  constructor() {}

  build(): AuthorizationPolicy {
    return new AuthorizationPolicy(this.requirements);
  }

  AddRequirements(requirements: IAuthorizationRequirement[]): AuthorizationPolicyBuilder {
    this.requirements.push(...requirements);
    return this;
  }

  RequireScopePermission(scope: string, permission: Permission): AuthorizationPolicyBuilder {
    const scopePermission = new ScopePermission(scope, permission);
    const requirement = new ScopePermissionRequirement(scopePermission);
    this.requirements.push(requirement);
    return this;
  }

  RequireAssignmentScopePermission() {
    const assignmentScopePermission = new ScopePermission(WellKnownScopes.assignment, Permission.read);
    const assignmentsAllScopePermission = new ScopePermission(WellKnownScopes.assignmentsAll, Permission.read);

    const requirement = new AssignmentScopePermissionRequirement(
      assignmentScopePermission,
      assignmentsAllScopePermission,
    );
    this.requirements.push(requirement);
    return this;
  }

  RequireAuthenticatedUser(): AuthorizationPolicyBuilder {
    const requirement = new DenyAnonymousRequirement();
    this.requirements.push(requirement);
    return this;
  }
}
