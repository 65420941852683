import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'call',
  standalone: true,
})
export class CallPipe implements PipeTransform {
  transform<TFunc extends (...args: any[]) => any>(fn: TFunc, ...args: unknown[]): ReturnType<TFunc> {
    if (fn instanceof Function) {
      return fn.call(null, ...args);
    }
    return fn;
  }
}
