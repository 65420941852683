import { IAuthorizationRequirement } from '../authorizationrequirement';
import { ScopePermission } from '../models/scopepermission';

export class AssignmentScopePermissionRequirement implements IAuthorizationRequirement {
  assignmentScopePermission: ScopePermission;
  assignmentsAllScopePermission: ScopePermission;
  name: string;

  constructor(assignmentScopePermission: ScopePermission, assignmentsAllScopePermission: ScopePermission) {
    this.assignmentScopePermission = assignmentScopePermission;
    this.assignmentsAllScopePermission = assignmentsAllScopePermission;
    this.name = `AssignmentScopePermissionRequirement`;
  }
}
