import { NgModule, InjectionToken } from '@angular/core';

import { IAuthorizationHandler } from './authorizationhandler';
import { AssignmentScopePermissionHandler } from './handlers/assignmentscopepermission.handler';
import { ClaimsHandler } from './handlers/claims.handler';
import { DenyAnonymousHandler } from './handlers/denyanonymous.handler';
import { ScopePermissionHandler } from './handlers/scopepermission.handler';

export const AUTH_HANDLERS = new InjectionToken<IAuthorizationHandler>('authorization handlers');

@NgModule({
  imports: [],
  providers: [
    { provide: AUTH_HANDLERS, useClass: ScopePermissionHandler, multi: true },
    { provide: AUTH_HANDLERS, useClass: ClaimsHandler, multi: true },
    { provide: AUTH_HANDLERS, useClass: AssignmentScopePermissionHandler, multi: true },
    { provide: AUTH_HANDLERS, useClass: DenyAnonymousHandler, multi: true },
  ],
})
export class SecurityModule {}
