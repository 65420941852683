import { Inject, Injectable, Injector } from '@angular/core';
import { ToastrService, IndividualConfig } from 'ngx-toastr';

import { TranslateExtensionService } from '~/v2/core/services/translate-extension.service';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private defaultErrorMessage = 'error.something-went-wrong-try-again-or-contact-us';

  private translateService: TranslateExtensionService;

  constructor(
    @Inject(Injector) private readonly injector: Injector,
    translateService: TranslateExtensionService,
  ) {
    //this.toastr.toastrConfig.preventDuplicates = true;
    this.translateService = translateService;
  }

  success(message: string, parameters?: any): void {
    const options = this.configureOptions();
    this.toastr.success(this.getTranslation(message, parameters), undefined, options);
  }

  private get toastr(): ToastrService {
    return this.injector.get(ToastrService);
  }

  warning(message: string, parameters?: any): void {
    const options = this.configureOptions();
    this.toastr.warning(this.getTranslation(message, parameters), undefined, options);
  }

  error(message: string | object | null = null, parameters?: any): void {
    if (message == null || typeof message === 'object') {
      message = this.defaultErrorMessage;
    }

    const timeout = 8000;
    const options = this.configureOptions(timeout);
    this.toastr.error(this.getTranslation(message, parameters), undefined, options);
  }

  private getTranslation(message: string, parameters?: any): any {
    return this.translateService.getInstantTranslation(message, parameters);
  }

  private configureOptions(timeout = 5000): Partial<IndividualConfig> {
    return {
      positionClass: 'alert-fixed',
      easeTime: 0,
      timeOut: timeout,
    };
  }
}
