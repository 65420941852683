{
  "welcome-header": "Welcome to Magnit",
  "welcome-text-1": "Handle all your business online. Log in for assignments, screenings, timesheets, contracts and invoices.",
  "welcome-text-2": "Accessible anywhere, anytime. Magnit, the Evolution of Work.",
  "login-header": "Login",
  "login-text": "Next",
  "login-supplier": "Login as supplier, self-employed professional or expert",
  "login-customer": "Login as client",
  "login-customer-migrated": "Login as client (migrated)",
  "login-customer-not-migrated": "Login as client (not migrated)",
  "login-netive": "Login for one of the following organisations",
  "register-header": "No account yet?",
  "register-text": "Register to get access to the Magnit portal.",
  "register-button": "Register now",
  "questions-header": "Questions?",
  "account": {
    "add-company-details": "Add company details",
    "edit-profile": "Profile",
    "job-employee": "Employee",
    "logout": "Logout",
    "profile": "Profile",
    "switch": "Switch account"
  },
  "address": {
    "business": "Business",
    "postal": "Postal"
  },
  "assignment": {
    "category-notstarted": "Not started",
    "category-started": "Started",
    "category-stopped": "Finished"
  },
  "assignment-contact": {
    "text-1": "Do you have questions about this assignment? For contract inquiries, worker extensions or working requirements, please contact <b>{{contactName}}</b> directly via <A HREF='mailto:{{contactEmailAddress}}'>{{contactEmailAddress}}</A>.",
    "text-2": "For all other inquiries, please submit a support ticket via our <A HREF='https://support.magnitglobal.com/help' target='_blank'>Magnit Global Services and Support Center</A>, or call our support team via <A HREF='tel:+31 (0) 30 602 16 17'>+31 (0) 30 602 16 17</A> (Monday to Friday, from 9 a.m. to 4 p.m.).",
    "text-3": "For questions regarding overdue invoices and wrong payments, please submit a support ticket via our <A HREF='https://support.magnitglobal.com/help' target='_blank'>Services and Support Center</A>.",
    "title": "Contact with Magnit"
  },
  "assignment-hours-and-expenses": {
    "expense-activity": "Activity",
    "expenses-column-tooltip": "Approved expenses (excl. VAT). Draft, submitted and/or rejected expenses between brackets.",
    "expenses-title": "Expenses",
    "hours-column-tooltip": "Approved hours. Draft, submitted and/or rejected hours between brackets.",
    "hours-title": "Hours",
    "no-data-yet": "There are no timesheets and expenses.",
    "no-records-for-period": "No hours worked or expenses have been registered (yet) in this period.",
    "search-gave-no-records": "There are currently no timesheets and expenses that meet these criteria.",
    "title": "Details hours & expenses"
  },
  "assignment-insurance-page": {
    "button-more-info": "More info",
    "button-cancel": "Cancel",
    "cancel-modal": {
      "button-cancel-insurance": "Cancel insurance",
      "button-do-not-cancel": "Do not cancel",
      "cancellation-date": "Your cancellation will take effect on:",
      "cancellation-successful": "Your insurance will be terminated on {{cancellationDate}}. Please have your new insurance checked immediately by going through the Alicia Insurance check-in, accessible via the button on this page.",
      "required-checkbox-text-1": "I understand that I must be adequately insured during my Magnit assignment, even after canceling the Alicia insurance.",
      "required-checkbox-text-2": "I understand that Magnit may be forced to terminate the agreement in the event that I cannot demonstrate that I am adequately insured.",
      "text": "Magnit asks all its suppliers to be adequately insured for the work they do. By canceling your current insurance from Alicia, we are requesting you to show that you have another professional and corporate liability insurance. Being adequately insured is an important condition of the agreement.<br><br>The cancellation will take effect on the first of the month you specify below (where the cancellation date is at least 14 days in the future). At that time, you will no longer be insured through Alicia for your assignment. <b>PLEASE NOTE</b>: You will need to provide proof of new insurance in our Portal <u>before that date</u>.<br><br>Are you sure you want to cancel your current Alicia insurance?",
      "title": "Confirm cancellation"
    },
    "header": "Professional and corporate liability insurance",
    "policy-end-date": "End date",
    "policy-number": "Policy number",
    "policy-number-tooltip": "Unfortunately this field cannot be filled at the moment. For the policy number please check your policy document with Alicia.",
    "policy-status": "Status",
    "policy-status-active": "Active",
    "policy-status-inactive": "Inactive",
    "policy-status-not-applicable": "Not applicable",
    "policy-start-date": "Start date",
    "text-alicia-insurance": "For this assignment, you have chosen to get insured for business liability and professional liability via our partner Alicia.\n\nYou can find more information about your insurance using the button below.",
    "text-no-insurance": "You are currently not (yet) insured via our partner Alicia for this assignment for professional and corporate liability, but in a different way.\n\nDo you want to know more about the insurances that Alicia offers? Click on the button below."
  },
  "assignment-invoices-page": {
    "early-payments-information": "More information about early payments"
  },
  "assignment-overview-page": {
    "agreed-terms": "Agreed Terms",
    "column-assignment-number": "Assignment number",
    "column-candidate": "Expert and position",
    "column-contract-action": "Contract (action required)",
    "column-customer": "Client",
    "column-enddate": "End date",
    "column-hire-number": "Hiring number",
    "column-number": "Request number",
    "column-remarks": "Details",
    "column-screening-action": "Screening (action required)",
    "column-startdate": "Start date",
    "column-tofollow": "Date to be announced",
    "complete-company-details": "Complete company details",
    "contact": "Contact",
    "contracts": "Contracts",
    "fee-agreement-applicable": "Fee Agreement applicable",
    "hours": "Timesheets",
    "hours-per-week": "Hours per week",
    "incomplete-company-details": "The company details are not complete. The contract cannot be drawn up without this information and a completed screening. Please complete these details as soon as possible.",
    "incomplete-company-details-contact-brainnet": "Your company details are incomplete. Please contact the Client Services Team using the contact information on the assignment.",
    "insurance": "Insurance",
    "invoice": "Invoices",
    "invoices": "Invoices",
    "not-started": "Not started",
    "payment-proposals": "Payment proposals",
    "period": "Period",
    "personal-details": "Expert details",
    "place-of-work": "Place of work",
    "plus-package": "<b class='plus-sign'>+&hairsp;</b> Assignment",
    "professional-personal-details": "Personal details of the Expert",
    "screening": "Screening",
    "specifics": "Specifics",
    "supplier-contact": "Screening assigned to",
    "supplier-name": "Supplier",
    "tab-contact": "Contact",
    "tab-contracts": "Contracts",
    "tab-insurance": "Insurance",
    "tab-invoices": "Invoices",
    "tab-overview": "Overview",
    "tab-payment-proposals": "Payment proposals",
    "tab-professional": "Expert details",
    "tab-screening": "Screening",
    "title": "Assignment",
    "title-prefixed": "Your assignment",
    "type-of-assignment": "Type of assignment"
  },
  "assignment-overview-screening-page": {
    "accepted": "Accepted",
    "action-required": "Action required",
    "action-required-professional": "In progress or action required by Expert",
    "action-required-professional-text": "This item is in progress by Magnit or an action is required from the Expert.",
    "action-required-text": "An action is required for this item.",
    "add-personal-details": "Add Expert details",
    "appointment-scheduled": "Appointment is scheduled, waiting on identity check",
    "approved": "Approved",
    "completed": "Completed",
    "completed-on": "Completed on",
    "completed-text": "This item has been checked and approved by Magnit and the client.",
    "complete-personal-details": "Complete personal details",
    "correct-information": "Correct the provided information",
    "explanation-title": "Explanation",
    "in-process": "In process",
    "in-progress": "In progress",
    "in-progress-text": "This item is in progress by Magnit.",
    "in-review": "Provided information is being reviewed by Magnit",
    "no-screening-yet": "There is no screening for this assignment yet.",
    "not-provided": "Has not been provided yet",
    "privacy": "Before the assignment can commence, we perform a pre-employment screening on behalf of the client. A proper screening is required to ensure that the Expert has all the necessary qualifications, papers, diplomas, checked references, etc. We check these things to ensure there aren’t any surprises for the clients.\n\nTo perform the screening, we need several personal details. At Magnit, we value privacy, but it’s impossible to complete the screening without these details. How Magnit handles personal details can be found in our",
    "privacy-conclusion": "\n\nThe required information and documents can be found under ‘Screening’. Some of the items need to be provided by the Expert. That is why you have the option to have the Expert provide the required information and documents digitally. By clicking ‘Share with Expert’ in the upper right corner, the Expert is requested via e-mail to complete the screening digitally.\n\nIn this part of the portal, you can view which information has already been provided. You can also see which items still require action and by whom. Further explanation of the different icons is offered below:",
    "privacy-conclusion-professional": "\n\nIn this part of the portal, you can view what information has already been provided. You can also see which items still require action. Further explanation of the different icons is offered below:",
    "privacy-professional": "Before the assignment can commence, we perform a pre-employment screening on behalf of the client. A proper screening is required to ensure that you have all the necessary qualifications, papers, diplomas, checked references, etc. We check these things to ensure there aren’t any surprises for the clients.\n\nTo perform the screening, we need several personal details. At Magnit, we value privacy, but it’s impossible to complete the screening without these details. How Magnit handles personal details can be found in our",
    "privacy-statement": "privacy statement",
    "rejected-action-required": "Rejected, action required",
    "rejected-action-required-text": "This item has been checked and rejected by Magnit. An additional action is required from you or the Expert.",
    "rejected-action-required-text-professional": "This item has been reviewed and rejected by Magnit. An additional action is required.",
    "screening-delegation-failed": "The Expert has <u>not</u> been invited. Please try again.",
    "screening-delegation-succeeded": "The Expert will be invited to complete the screening digitally.",
    "screening-documents": "Screening documents",
    "screening-not-online": "This screening won't be carried out via the portal.",
    "share-with-candidate": "Share with Expert",
    "translations-disclaimer": "<b>Please note</b>: The translations of all English documents were prepared with the utmost care. However, the parties to these documents do not accept any liability for errors or omissions in these translations or the direct or indirect consequences of acting or failing to act based on these translations. It is not possible to derive any rights, of whatever nature, from the compilation and contents of the translations. In all cases, the Dutch documents shall be decisive. You can access the Dutch documents by changing the language of the screening under 'My profile'.",
    "vertical-tab-explanation": "Explanation",
    "waiting-approval": "Provided information is being reviewed by Magnit",
    "waiting-integrity-statements": "Waiting on integrity statements from former employers",
    "waiting-professional-details": "First enter the Expert details"
  },
  "assignments-page": {
    "tab-notstarted": "Hasn't started yet",
    "tab-started": "Ongoing",
    "tab-stopped": "Finished",
    "title": "Assignments"
  },
  "billingperiod": {
    "four-four-five": "4-4-5",
    "four-weeks": "4-Weekly period",
    "month": "Month",
    "quarter": "Quarter",
    "six-months": "Six months",
    "two-weeks": "2-Weekly period",
    "week": "Week",
    "year": "Year"
  },
  "billing-period-filter": {
    "current-period": "Current period",
    "custom-period": "Custom period",
    "divergent-billing-period-type-alert": "<b>Please be aware:</b> This assignment is invoiced per <b>{{billingPeriod}}</b>.",
    "number": "Number",
    "period": "Period",
    "specific-period": "Specific period",
    "up-to-and-including": "To"
  },
  "common": {
    "action-cannot-be-undone": "This can't be undone",
    "add-document": "Add another document",
    "amount-including-vat": "Amount (incl. VAT)",
    "archived": "Archived",
    "are-you-sure": "Are you sure?",
    "asap": "ASAP",
    "assignment": "Assignment",
    "back": "Back",
    "cancel": "Cancel",
    "change": "Edit",
    "characters": "characters",
    "close": "Close",
    "closed": "Closed",
    "continue": "Continue",
    "correction": "Correct",
    "date": "Date",
    "date-created": "Date created",
    "days": "days",
    "deadline": "Deadline",
    "delete": "Delete",
    "download": "Download",
    "download-all-documents": "Download all documents",
    "edit": "Edit",
    "expired": "Expired",
    "explanation": "Additional information",
    "file-invalid": "The file could not be uploaded.",
    "file-size-too-large": "File is too big.",
    "file-type-not-allowed": "File type not allowed",
    "friday": "Friday",
    "from": "From",
    "hour": "hrs",
    "hourly-rate": "As competitive as possible",
    "invite": "Invite",
    "monday": "Monday",
    "month-1": "January",
    "month-10": "October",
    "month-11": "November",
    "month-12": "December",
    "month-2": "February",
    "month-3": "March",
    "month-4": "April",
    "month-5": "May",
    "month-6": "June",
    "month-7": "July",
    "month-8": "August",
    "month-9": "September",
    "next": "Next",
    "no": "No",
    "no-results": "No results found",
    "not-acquired": "N/A",
    "not-applicable": "Not applicable",
    "not-applicable-abbreviation": "N/A",
    "ok": "Ok",
    "period": "Period",
    "postal-code-invalid": "Incorrect notation (i.e. 1234 AB)",
    "previous": "Previous",
    "provided-information": "Provided information",
    "results-shown": "Results shown",
    "return-to-overview": "Back to overview",
    "revert": "Withdraw",
    "saturday": "Saturday",
    "save": "Save",
    "search": "Search",
    "send": "Send",
    "settings": "Settings",
    "skip": "Skip",
    "something-went-wrong": "Something went wrong.",
    "something-went-wrong-try-again-or-contact-us": "Unfortunately, something went wrong. Please try again later or <a href='/supplier/support/contact'>contact</a> Magnit.",
    "status": "Status",
    "status-approved": "Approved",
    "status-concept": "Draft",
    "status-rejected": "Rejected",
    "status-submitted": "Submitted",
    "sunday": "Sunday",
    "tba": "TBA",
    "thursday": "Thursday",
    "till": "till",
    "to": "To",
    "to-follow": "To follow",
    "tooltip-contact-brainnet-for-changes": "This cannot be edited. If necessary, please contact Magnit.",
    "total-amount-week": "Total amount wk",
    "total-today": "Total today",
    "total-week": "Total wk",
    "tuesday": "Tuesday",
    "unauthorised": "You were automatically logged out for security reasons. Please refresh the page to log in again.",
    "unknown": "Unknown",
    "unspecified": "Unspecified",
    "upload": "Upload",
    "verify-and-send": "Verify and send",
    "waiting-for-approval": "Waiting for approval",
    "wednesday": "Wednesday",
    "week": "Week",
    "yes": "Yes"
  },
  "company-information": {
    "business-organisation": {
      "delivers-professionals": "My company supplies Experts",
      "self-employed": "I am a self-employed Expert"
    },
    "complete": "Complete",
    "incomplete": "Company details incomplete",
    "rejected": "Company details rejected"
  },
  "contactperson": {
    "financial": "Financial",
    "main": "Main",
    "signer": "Signer"
  },
  "contractactions": {
    "afgerond": "Afgerond",
    "brainnet": "Magnit",
    "complete": "Completed",
    "supplier": "Supplier",
    "unspecified": "Unspecified",
    "waiting-on-screening": "Waiting for screening"
  },
  "contracts": {
    "active-contracts": "Active contracts",
    "attachments": "Annexes",
    "client": "Client",
    "client-name": "Client",
    "contract-details": "Contract details",
    "contract-number": "Contract number",
    "contract-period": "Period (from/to)",
    "contracts": "Contracts",
    "contracts-to-sign": "Contracts to be signed",
    "contract-type": "Contract type",
    "contract-types": {
      "contract-period-agreement": "Work order",
      "model-agreement": "Service contract",
      "service-agreement": "Service contract"
    },
    "date-signed": "Date signed",
    "details": "Details",
    "download-contract": "Download contract",
    "download-entrepreneurs-assessment": "Download Entrepreneur's assessment",
    "drawn-up": "Drawn Up",
    "expert-name": "Expert",
    "expired-contracts": "Expired contracts",
    "no-active-contracts-available": "There are no active contracts.",
    "no-contracts-available-for-this-assignment": "There are no contracts for this assignment yet.",
    "no-contracts-found": "There are no contracts that meet these criteria.",
    "no-expired-contracts-available": "There are no expired contracts.",
    "no-signed-contracts-available": "There are no signed contracts.",
    "no-unsigned-contracts-available": "There are no contracts waiting to be signed.",
    "sign-before": "Sign before",
    "sign-contract": "Sign contract",
    "signed-by": "Signed by",
    "signed-contracts": "Signed contracts",
    "signed-on": "Signed on",
    "signing-blocked-by-missing-coc-number-error": "You can not sign this contract because we have not yet received you Chamber of Commerce number.",
    "signing-status": {
      "ready-for-signing": "Waiting on signing",
      "signed": "Signed",
      "waiting-for-approval": "Waiting for approval"
    },
    "to-assignment": "To assignment",
    "unsigned-contracts": "Unsigned contracts"
  },
  "contracts-sign-before-date": "Sign before:",
  "create-candidate": {
    "city": "City",
    "country": "Country",
    "dateofbirth": "Date of birth",
    "firstname": "Given name",
    "firstnames": "First names",
    "initials": "Initials",
    "lastname": "Surname",
    "nationality": "Nationality",
    "personal-email": "Personal email address for the candidate",
    "postalcode": "Postal code",
    "prefix": "Prefix (optional)",
    "title": "Add candidate"
  },
  "datatable": {
    "assignments-empty-data": "There are no assignments that meet these criteria",
    "assignments-no-ended-data": "There are currently no finished assignments.",
    "assignments-no-ended-data-title": "Finished assignments",
    "assignments-no-notstarted-data": "There are currently no assignments which have been started.",
    "assignments-no-notstarted-data-title": "Not started assignments",
    "assignments-no-started-data": "There are currently no ongoing assignments.",
    "assignments-no-started-data-title": "Ongoing assignments",
    "jobrequests-archived-empty-data": "There are currently no archived proposals that meet these criteria.",
    "jobrequests-favourite-empty-data": "There are currently no favourite job requests that meet these criteria.",
    "jobrequests-hidden-empty-data": "There are currently no hidden job requests that meet these criteria.",
    "jobrequests-new-empty-data": "There are currently no new job requests that meet these criteria.",
    "jobrequests-no-archived-data": "There are currently no archived proposed candidates.",
    "jobrequests-no-drafts-data": "There are currently no draft job requests.",
    "jobrequests-no-favorite-data": "There are currently no favourite job requests. Mark a job request as favourite to see it in this list.",
    "jobrequests-no-hidden-data": "There are currently no hidden job requests.",
    "jobrequests-no-new-data": "There are currently no new job requests.",
    "jobrequests-no-proposed-data": "There are no proposed candidates yet, or the proposed candidate is already archived.",
    "jobrequests-proposed-empty-data": "There are no proposed candidates that meet these criteria.",
    "no-data": "There is no data found"
  },
  "delegate-screening": {
    "company-candidate-relationship": "What is the employment relationship between the Expert and your company?",
    "invite-candidate": "Invite the Expert to provide the required screening information. You can check the progress in the portal.",
    "personal-address": "Please note: Use a personal e-mail address that is valid before, during and after the assignment.",
    "title": "Share with Expert"
  },
  "detailed-explanation-of-the-professional-and-corporate-liability-risk": "Detailed explanation of the professional and corporate liability risk",
  "download-hours-expenses-report-modal": {
    "download-choice": "Which report do you want to download?",
    "download-choice-both": "Both reports",
    "download-choice-expenses": "Expense report",
    "download-choice-hours": "Timesheet",
    "download-title": "Download timesheet and expense report"
  },
  "draftproposalstatus": {
    "closed": "Request expired",
    "concept": "Draft proposal"
  },
  "employments-page": {
    "tab-all": "All employments",
    "tab-notstarted": "Hasn't started yet",
    "tab-started": "Ongoing",
    "tab-stopped": "Finished"
  },
  "employment-type-options": {
    "employee": "Employed (Expert is employed by you)",
    "employee-short": "Employed",
    "zzp-direct": "Self-employed (you are self-employed)",
    "zzp-direct-short": "Self-employed",
    "zzp-indirect": "Self-employed (the Expert is a self-employed professional who is subcontracted by your company)",
    "zzp-indirect-short": "Self-employed (sub-contracted)"
  },
  "enrich-candidate": {
    "title": "Add Expert details"
  },
  "entrepreneurial-assessment": {
    "are-you-entrepreneur": "Are you an Entrepreneur?",
    "city": "City",
    "complete-entrepreneurial-assessment": "Complete Entrepreneur's assessment",
    "contract-signing-failed": "The contract is not signed. Do you have a question? Please contact the Client Services Team via the contact details on the assignment.",
    "contract-signing-succeeded": "The contract is signed and will be verified by Magnit.",
    "declaration": "Declaration",
    "entrepreneurial-assessment": "Entrepreneur's assessment",
    "position": "Position",
    "question1a": "Are you an entrepreneur?",
    "question1a-tooltip": "You are an entrepreneur if you (as a self-employed professional without employees (zzp)) are registered with the Chamber of Commerce.",
    "question1b": "Since what year have you been an entrepreneur?",
    "question1b-placeholder": "e.g. 1996",
    "question1b-tooltip": "The year of incorporation of your company (this can also be found in the extract from the Chamber of Commerce).",
    "question2": "Do you have capital in the form of money and/or other goods?",
    "question2-tooltip": "If you perform work and invoice this work, you have capital in the form of money. If you have your own material, you have capital in the form of goods.",
    "question3": "Do you take on a 'default risk'?",
    "question3-tooltip": "As an entrepreneur you take on the risk that your invoices are not paid (on time), the so-called default risk. For example: your customer goes bankrupt or because of a legal/business dispute with your customer.",
    "question4": "Do you use your good reputation to perform the work?",
    "question4-tooltip": "An entrepreneur is awarded a contract for services because he has a specific expertise/can provide a specific service and is known (by reputation) to provide this service at the right quality and price.",
    "question5": "Are you dependent on the demand for your expertise, products and services?",
    "question5-tooltip": "An entrepreneur depends for his/her income on demand from customers.",
    "question6": "Are you liable for any debts your company has?",
    "question6-tooltip": "An entrepreneur is responsible for everything he/she does within the framework of his/her business activities.",
    "question7": "An entrepreneur is required by law to keep records or have them kept.",
    "question7-tooltip": "An entrepreneur is responsible for everything he/she does within the framework of his/her business activities.",
    "spplier-contact-statement": "I, {{username}}, hereby declare that the Entrepreneur's assessment was completed truthfully:",
    "supplier-statement": "{{supplier}}, registered office and place of business at {{location}}, registered with the Chamber of Commerce number {{chamberOfCommerceNumber}}, duly represented by its director(s), states the following:",
    "why": "This Entrepreneurial assessment is an integral part of the Service Contract, therefore we ask you to complete this assessment.",
    "you-answered-no": "You answered 'no' to a question. Please contact the Client Services Team via the contact details on the assignment."
  },
  "error": {
    "account-locked-header": "This account is temporarily locked",
    "account-locked-text": "This account is temporarily locked, so you are unable to log in. Please try again later or contact Magnit by phone via <A HREF='tel:+31 (0) 30 602 16 17'>+31 (0) 30 602 16 17</A> or send an e-mail to <A HREF='mailto:infoNL@magnitglobal.com'>infoNL@magnitglobal.com</A>.",
    "combination-already-exists-add-explanation": "This combination already exists, add a note.",
    "combination-already-exists-add-other-explanation": "This combination already exists, add another note.",
    "company-information-failure": "Unfortunately, something went wrong (for example, check the format of your bank account). Please try again later or <a href='https://www.brainnet.nl/en/contact/'>contact</a> Brainnet.",
    "date-before-max-months-in-past": "It is not possible to declare expenses longer than {{months}} months in the past.",
    "date-not-in-selected-period": "The selected date is outside the contract period.",
    "date-not-in-selected-week": "The chosen date falls outside of the selected week. Please choose another week.",
    "duplicate-email-address": "This e-mail address is already filled out.",
    "expense-greater-than-max-amount": "You may claim a maximum of € 2500,-.",
    "expense-smaller-than-min-amount": "Amount must be greater than 0.",
    "general": "Something went wrong",
    "incorrect-notation": "Incorrect notation",
    "kilometers-less-than-min": "Mileage must be larger than 0.",
    "length": "The amount of characters is invalid",
    "maxLength": "Max. number of characters exceeded",
    "minLength": "Not enough characters entered",
    "notEqualTo": "Required field",
    "olderThan18": "The minimum age is 18 years",
    "pattern": "Incorrect character",
    "profile-supplier": {
      "coc-length-error": "CoC number should consist of 8 numbers",
      "coc-number-error": "The provided CoC number is incorrect",
      "coc-registration-end-date-in-past": "The company related to this CoC number is no longer registered at the Dutch Chamber of Commerce."
    },
    "repeated-email-does-not-match": "The email address does not match",
    "required": "Required field",
    "requiredTrue": "Required field",
    "screening-big-check-page": {
      "big-number-length-error": "The BIG number should consist of 11 numbers. If your BIG number is less than 11 numbers long, add zeroes to the front of the initial number until it consists of 11 numbers in total"
    },
    "something-went-wrong-try-again-or-contact-us": "Unfortunately, something went wrong. Please try again later or <a href='/supplier/support/contact'>contact</a> Magnit.",
    "zzp-employmenttype-not-allowed": "If you are fulfilling the assignment yourself, you need to provide the screening information. Which information and documents are needed, can be found under ‘Screening’"
  },
  "expense-edit-page": {
    "delivery-date-kilometer": "Travel date",
    "delivery-date-otherexpense": "Date of expense",
    "description": "Description (optional)",
    "expense-status-approved": "Approved",
    "expense-status-draft": "Draft",
    "expense-status-rejected": "Rejected",
    "expense-status-submitted": "Submitted",
    "label-actions": "Actions",
    "label-costcenters": "Cost center",
    "label-description": "Description",
    "label-expense-amount": "Amount excl. VAT",
    "label-expense-quantity": "Number of kilometres",
    "label-products": "Activity",
    "label-product-type": "Type of expense claim",
    "label-specification": "Details",
    "label-upload-receipt": "Upload receipt",
    "popup-title-edit": "Change expense claim",
    "popup-title-new": "Add new expense claim",
    "product-type-kilometer": "Claim mileage",
    "product-type-other-expense": "Claim expenses",
    "select-activity": "Select activity",
    "select-costcenter": "Select cost center"
  },
  "expenses-edit-page": {
    "button-add-declaration": "Add expense claim",
    "button-submit": "Submit",
    "column-amount": "Amount",
    "column-costcenter": "Cost center",
    "column-date": "Date",
    "column-deliverydate": "Date of expense",
    "column-producttype": "Activity",
    "column-total": "Total",
    "column-total-amount": "Total amount",
    "date-seven-days-in-future": "It is not possible to claim expenses further than 1 week in the future.",
    "document-uploaded": "The receipt has been uploaded.",
    "error-max-amount-warning": "You may submit a maximum of € 2500,- per claim.",
    "expense-description": "Description",
    "expense-details": "Details",
    "expense-removed": "The expenses are deleted.",
    "expenses-reverted": "The submitted expenses are withdrawn.",
    "expenses-submitted": "The expenses are submitted.",
    "expenses-submitted-week": "The expenses for week {{week}} are submitted.",
    "info-no-expenses": "No expenses have been claimed in this week. Add a new expense claim.",
    "label-new": "<New expense claim>",
    "label-total": "Total",
    "label-total-week": "Total of this week",
    "navigate-to-hours": "Register hours worked",
    "no-expenses-yet": "No expenses have been claimed.",
    "no-hours-yet": "No hours worked have been registered.",
    "saved-dont-forget-submit": "The expenses are saved. Don't forget to submit these expenses.",
    "saved-dont-forget-submit-week": "The expenses for week {{week}} have been saved. Don't forget to submit these expenses.",
    "title": "Claim expenses",
    "tooltip-ex-vat": "Claimed expense amount excluding VAT.",
    "withdraw": "Withdraw",
    "withdraw-submitted-expenses": "Withdraw submitted expenses",
    "withdraw-submitted-expenses-are-you-sure": "Are you sure you want to withdraw the submitted expenses?"
  },
  "expenses-status-modal": {
    "bullet-1": "The following statuses are applicable on claimed expenses:",
    "bullet-2": "You may only invoice approved expenses.",
    "bullet-3": "It is not possible to adjust approved expenses.",
    "bullet-4": "Submitted expenses must be withdrawn before you can adjust them.",
    "bullet-5": "Rejected expenses can be adjusted and re-submitted straight away.",
    "title": "Information about claiming expenses"
  },
  "expertises": {
    "analysis": "Analysis",
    "bank-insurance": "Bank / Insurance",
    "construction": "Construction / Civil Engineering",
    "development": "Development",
    "education": "Education",
    "energy": "Energy / Utilities",
    "finance": "Finance",
    "hr-staff-legal": "HR / Staff / Legal",
    "ict": "ICT",
    "infra": "Infra / Roads",
    "installation": "Installation Technology",
    "maintenance": "Maintenance / Support",
    "mechanical": "Mechanical Engineering / Electrical Engineering / Industrial Automation",
    "medical": "Medical / QHSE",
    "other": "Other",
    "other-it": "Other (IT)",
    "sales": "Sales / MarCom",
    "social-enterprising": "Social enterprising",
    "water": "Water"
  },
  "file-drop": {
    "download-boundaries": "File type: .pdf, .xlsx, xls, .jpeg, .png, .doc or .docx  | Maximum file size: 5MB.",
    "download-instruction": "Click to select a document or drag it here."
  },
  "filters": {
    "candidate-status-title": "Candidate status",
    "contract-action-title": "Contract (action required)",
    "contract-filter": {
      "contract-expires-within": "Contract expires within",
      "contract-expires-within-one-month": "1 month",
      "contract-expires-within-six-months": "6 months",
      "contract-expires-within-three-months": "3 months",
      "contract-expires-within-two-months": "2 months",
      "contract-is-expired": "Contract is expired",
      "contract-is-expired-within-last-month": "Last month",
      "contract-is-expired-within-last-six-months": "Last 6 months",
      "contract-is-expired-within-last-three-months": "Last 3 months",
      "contract-is-expired-within-last-two-months": "Last 2 months",
      "sign-contract-before": "Sign before",
      "sign-contract-before-next-month": "Next month",
      "sign-contract-before-next-two-weeks": "Next 2 weeks",
      "sign-contract-before-next-week": "Next week",
      "sign-contract-before-tomorrow": "Tomorrow"
    },
    "date-created-in-past-title": "Create date in the past",
    "expertises-title": "Expertises",
    "hours-per-week-title": "Hours per week",
    "invoice-date-title": "Invoice date in the past",
    "invoice-filter": {
      "credit": "Credit",
      "debet": "Debit",
      "in-eight-days": "8 days",
      "in-fifteen-days": "15 days",
      "in-sixty-days": "60 days",
      "in-thirty-days": "30 days",
      "one-month": "month",
      "one-week": "1 week",
      "six-months": "6 months",
      "three-months": "3 months",
      "twelve-months": "12 months",
      "twenty-four-months": "24 months",
      "two-months": "2 months",
      "two-weeks": "2 weeks"
    },
    "invoice-type-title": "Invoice type",
    "last-change-title": "Latest change",
    "longer-than-two-days-ago": "Longer than two days ago",
    "options-to-extent-title": "Options to extend",
    "option-to-extend-title": "Option to extend",
    "payment-date-title": "Payment date within",
    "screening-action-title": "Screening (action required)",
    "search-text": "Search",
    "self-employment-allowed-title": "Self-employed allowed",
    "title": "Filters",
    "today": "Today",
    "two-days-ago": "Two days ago",
    "yesterday": "Yesterday"
  },
  "filters-two-days-ago": "Two days ago",
  "function-requirements-profile": {
    "button": "Job requirement profile",
    "download-instruction": "This assignment has specific requirements. Please download the requirements profile below then complete and upload it.",
    "label-upload": "Upload job requirement profile",
    "skip-required": "This job request doesn't need a requirements profile.",
    "title": "Function requirement profile",
    "upload-success": "The function requirement profile was uploaded."
  },
  "gender": {
    "man": "Male",
    "title": "Gender",
    "woman": "Female"
  },
  "home": {
    "approve-payment-proposals": "Approve payment proposals",
    "assignments": "Assignments",
    "categories": {
      "company-information": "Company details",
      "contract": "Contracts",
      "payment-proposal": "Payment proposals",
      "screening": "Screening"
    },
    "category": "Category",
    "client": "Client",
    "complete-company-information": "Complete company details",
    "contact-brainnet": "Contact Magnit",
    "declare-expenses": "Claim expenses",
    "details": "Details",
    "drafts": "Drafts",
    "expense-report": "Expense report",
    "expert": "Expert",
    "expert-and-role": "Expert (Position)",
    "favorites": "Favorites",
    "finished": "Finished",
    "go-to-assignment": "Go to assignment",
    "go-to-assignments": "Go to assignments",
    "go-to-jobrequest": "Go to job requests",
    "hasnt-started-yet": "Hasn't started yet",
    "jobrequests": "Job requests",
    "my-oustanding-actions": "My outstanding actions",
    "newest-jobrequests": "Newest job requests",
    "new-jobrequests": "New job requests",
    "no-new-jobrequests": "There are currently no new job requests.",
    "ongoing": "Ongoing",
    "outstanding-action": "Outstanding action",
    "outstanding-actions": "Outstanding actions",
    "outstanding-screening-items": "outstanding screening items",
    "perform-screenings": "Perform screenings",
    "proposals": "Proposals",
    "proposed": "Proposed",
    "register-hours-worked": "Register worked hours",
    "role": "Role",
    "sign-contracts": "Sign contracts",
    "there-are-no-outstanding-actions": "There are no outstanding actions",
    "timesheets": "Timesheet",
    "timesheets-and-expense-report": "Timesheet and expense report",
    "via": "via",
    "welcome": "Welcome",
    "with-contract-number": "with contract number",
    "your-assignment": "Your assignment"
  },
  "hours": {
    "edit": {
      "max-hours-a-day-warning": "It is not possible to register more than {{maxAmount}} hours in one day.",
      "you-cant-register-hours-futher-than-one-week-in-future": "It is not possible to register hours worked further than 1 week in the future."
    }
  },
  "hours-and-expense": {
    "billable-product-status": {
      "approved": "Approved",
      "concept": "Draft",
      "rejected": "Rejected",
      "submitted": "Submitted"
    }
  },
  "hours-and-expenses": {
    "amount": "Amount",
    "amount-column-tooltip": "Approved hours multiplied by rate (excl. VAT).",
    "assignment-number": "Assignment number",
    "changes-are-not-saved": "Your changes have not been saved",
    "client-and-location": "Client",
    "declare-expenses": "Claim expenses",
    "download-expense-report": "Download expense report",
    "download-hours-and-expenses-report": "Download timesheet and expense report",
    "download-hours-report": "Download timesheet",
    "expenses": "Expenses",
    "expenses-column-tooltip": "Approved expenses (excl. VAT). Submitted and/or rejected expenses between brackets.",
    "expenses-explanation": "Approved expenses (excl. VAT). Draft, submitted and/or rejected expenses between brackets.",
    "expert-and-client": "Expert and Client",
    "from": "From",
    "from-to": "From/to",
    "hours": "Hours",
    "hours-and-expenses": "Hours and expenses",
    "hours-column-tooltip": "Approved hours. Submitted and/or rejected hours between brackets.",
    "professional-and-position": "Expert and Position",
    "register-hours-worked": "Register hours worked",
    "timesheets-and-expense-reports": "Timesheets and expense reports",
    "to": "To",
    "total": "Total",
    "total-amount": "Total amount",
    "total-amount-tooltip": "Approved hours multiplied by rate (excl. VAT) and approved expenses (excl. VAT).",
    "week-number": "Week number",
    "your-changes-will-be-lost": "You are about to leave without saving or submitting your changes. The changes will be lost."
  },
  "hours-edit": {
    "actions": "Actions",
    "activity": "Activity",
    "add-activity": "Add activity",
    "button-change-activity": "Change activity",
    "change-activity": "Change activity",
    "copy-previous-week": "Copy previous week",
    "cost-center": "Cost center",
    "customer-reference": "Customer reference",
    "day-is-outside-contract-period": "It is not possible to register hours worked, because this day is outside contract period.",
    "declare-expenses": "Claim expenses",
    "duplicate-previous-week-no-hours": "No hours were registered in the previous week. As a result, it is not possible to copy activities.",
    "duplicate-previous-week-succeeded": "Previous week's activities have been copied. You can now register hours worked.",
    "duplicate-previous-week-tooltip": "This function copies all activities from the previous week to the current week. Before saving or submitting you first need to add hours to the activities.",
    "hours": "Hours",
    "hours-are-saved": "The hours for week {{weekNumber}} are saved. Don't forget to submit these hours.",
    "hours-are-submitted": "The hours for week {{weekNumber}} are submitted.",
    "hours-for-day-are-saved": "The hours for {{day}} are saved. Don't forget to submit these hours.",
    "hours-for-day-are-submitted": "The hours for {{day}} are submitted.",
    "no-hours-yet-this-week": "No worked hours have been registered.<br>Add an <b>activity</b> or copy previous week's activities.",
    "no-hours-yet-this-week-copy-previous-week-available": "No worked hours have been registered in this week.<br>Add an <b>activity</b> or <b>copy previous week's activities</b>.",
    "no-hours-yet-this-week-copy-previous-week-unavailable": "No worked hours have been registered in this week.<br>Add an <b>activity</b>.",
    "no-hours-yet-today": "No worked hours have been registered. Add an <b>activity</b> or copy previous week's activities.",
    "no-hours-yet-today-copy-previous-week-available": "No worked hours have been registered. Add an <b>activity</b> or <b>copy previous week's activities</b>.",
    "no-hours-yet-today-copy-previous-week-unavailable": "No worked hours have been registered.<br>Add an <b>activity</b>.",
    "note": "Note",
    "rejected-by": "Rejected by",
    "select-activity": "Select activity",
    "select-costcenter": "Select cost center",
    "submit": "Submit",
    "the-hours-are-deleted": "The hours are deleted.",
    "week-is-outside-contract-period": "It is not possible to register hours worked, because this week is outside the contract period.",
    "week-is-too-far-in-history": "It is not possible to register hours worked longer than {{maxMonthsInHistory}} months in the past.",
    "withdraw": "Withdraw",
    "withdraw-hours": "Withdraw hours",
    "withdraw-submitted-hours": "Withdraw submitted hours",
    "withdraw-submitted-hours-are-you-sure": "Are you sure you want to withdraw the submitted hours?",
    "withdraw-submitted-hours-succeeded": "The submitted hours are withdrawn.",
    "write-hours": "Register hours worked"
  },
  "hours-status-modal": {
    "bullet-1": "The following statuses are applicable on registered hours:",
    "bullet-2": "You may only invoice approved hours.",
    "bullet-3": "It is not possible to adjust approved hours.",
    "bullet-4": "Submitted hours must be withdrawn before you can adjust them.",
    "bullet-5": "Rejected hours can be adjusted and re-submitted straight away.",
    "bullet-6": "You can't register more than 24 hours in one day.",
    "bullet-7": "Hours are automatically rounded to 15-minute units.",
    "bullet-8": "Per week you can only select one combination of activity and cost center, without having to add a note.",
    "bullet-assignment-expenses": "You may only invoice approved expenses. The approved expenses are excluding VAT. Submitted and/or rejected expenses between brackets.",
    "bullet-assignment-hours": "You may only invoice approved hours. Submitted and/or rejected hours between brackets.",
    "title": "Information about registering hours worked"
  },
  "information-delivery-status": {
    "date-received": "Date received",
    "provided-by": "Provided by",
    "reason-for-rejection": "Reason for rejection",
    "review-date": "Date reviewed"
  },
  "invoice-page": {
    "additional-service": "Additional service?",
    "additional-service-addendum": "This invoice will be offset against open payments",
    "button-download": "Download invoice",
    "credit": "Credit",
    "debit": "Debit",
    "invoice-date": "Invoice date",
    "invoice-type": "Invoice type",
    "payment-discount": "Early payment discount",
    "payment-term": "Payment term",
    "remarks": "Remark on invoice",
    "subheader": "Invoice details",
    "working-month": "Working month",
    "working-period": "Working period"
  },
  "invoices-page": {
    "column-additional-information": "Additional information",
    "column-amount": "Amount",
    "column-amount-and-payment-date": "Amount and Payment date",
    "column-customer": "Client",
    "column-download-available": {
      "to-follow": "To follow"
    },
    "column-download-available-header": "Download available?",
    "column-expert-and-client": "Expert and Client",
    "column-invoice-number": "Invoice number",
    "column-payment-date": "Payment date",
    "column-professional": "Expert",
    "column-to-receive-header": "To receive",
    "column-to-settle-header": "To settle",
    "column-working-month": "Working month",
    "credit-invoice": "Credit invoice",
    "no-records": "There are no invoices yet.",
    "payment-discount": "payment discount",
    "search-gave-no-records": "There are currently no invoices that meet these criteria.",
    "title": "Invoices"
  },
  "job-request": {
    "agreement-checkbox-text": "I have read the terms and conditions of the <b class='plus-sign'>+&hairsp;</b> assignment. I agree to pay a fee of €2.50 per invoiced hour when performing this assignment. Magnit will send me an invoice for this amount and set it off against the payment I receive from Magnit.",
    "agreement-description": "This is a <b class='plus-sign'>+&hairsp;</b> assignment to which additional terms and conditions apply.",
    "agreement-more-info": "More information",
    "agreement-title": "Agree to additional conditions",
    "confirmed-plus-text": "The <b class='plus-sign'>+&hairsp;</b> assignment and the associated charge of €2.50 per invoiced hour have been agreed upon.",
    "confirmed-plus-title": "Agreed to additional charge",
    "consent-given-to-agreement": "I have read the terms and conditions of the <b class='plus-sign'>+&hairsp;</b> assignment. I agree to pay a fee of €2.50 per invoiced hour when performing this assignment. Magnit will send me an invoice for this amount and set it off against the payment I receive from Magnit.",
    "plus-info-popup-text": "This is a <b class='plus-sign'>+&hairsp;</b> assignment that includes a <b class='plus-sign'>+&hairsp;</b> package. The <b class='plus-sign'>+&hairsp;</b> package comprises:<br><br><ul class='brainnet-ul'><li class='bullit-point'>Early payment within 15 days</li><li class='bullit-point'>Standard cover for professional liability and corporate liability risks</li></ul>The <b class='plus-sign'>+&hairsp;</b> package costs <b>€ 2.50 per invoiced hour</b> and includes Magnit services. Magnit will send you an invoice for this amount and set it off against the payment you receive from Magnit.",
    "plus-info-popup-title": "<b class='plus-sign'>+&hairsp;</b> Assignment"
  },
  "jobrequest-detail": {
    "candidate": "Candidate",
    "candidate-add": "Add a new candidate",
    "candidate-complete": "Complete candidate details",
    "candidate-not-listed": "Is the candidate not in the list?",
    "check-proposal": "Check details",
    "cla": "CLA",
    "contact-brainnet": "Responsible recruiter",
    "contact-modal": {
      "button-text": "Contact",
      "faq-link": "our FAQ page",
      "text-1": "Do you have questions about this request? For clarification on job requirements, work hours or salary, please contact <b>{{contactName}}</b> directly via <A HREF='mailto:{{contactEmailAddress}}'>{{contactEmailAddress}}</A>.",
      "text-2": "For questions on how to submit a candidate or the submission process, please start by seeing",
      "text-3": "For technical support, please submit a ticket via our <A HREF='https://support.magnitglobal.com/help' target='_blank'>Magnit Global Services and Support Center</A>, or call our support team via <A HREF='tel:+31 (0) 30 602 16 17'>+31 (0) 30 602 16 17</A> (Monday to Friday, from 9 a.m. to 4 p.m.).",
      "title": "Contact with Magnit"
    },
    "dateofbirth": "Date of birth",
    "detavast": "Temp-to-hire",
    "detavast-answer": "Yes",
    "expertises": "Expertises",
    "extra-information": "Additional information",
    "function-description": "Job description",
    "hourly-rate": "Max. rate per hour",
    "hours-per-week": "Hours per week",
    "jobrequest-expired": "The deadline for this job request has expired",
    "jobrequest-required": "Essential data is missing for this candidate",
    "jobrequest-saved": "The proposal was successfully saved as a draft",
    "location": "Location",
    "no-jobrequest-selected": "No job requests are selected",
    "option-to-extend": "Option to extend",
    "period": "Intended period",
    "placeholder-candidate": "Select a candidate",
    "placeholder-no-candidates": "Please add a new candidate first",
    "propose-candidate": "Propose Candidate",
    "repetitive-jobrequest": "This is a recurring job request",
    "required-experience": "Experience in years",
    "required-schooling": "Required level of education",
    "residence": "City",
    "save-proposal": "Save as draft",
    "scale": "Scale",
    "select-candidate": "Select a candidate",
    "self-employment-allowed": "Self-employed allowed",
    "send-proposal": "Send proposal",
    "title": "Job Request",
    "withdraw-proposal": "Withdraw proposal",
    "your-proposal": "You proposed"
  },
  "jobrequest-plus-package-details": {
    "link": "More information",
    "text": "<b>Please note:</b> this is a <b class='plus-sign'>+&hairsp;</b> assignment for which additional terms and conditions apply."
  },
  "jobrequest-search": {
    "asap": "ASAP",
    "column-deadline": "Deadline",
    "column-number": "Request number",
    "column-number-tooltip": "Unique job request number and the number of candidates proposed by your organization.",
    "column-options": "Options",
    "column-period": "Preferred start & location",
    "column-position": "Position",
    "isrepeated": "This is a recurring job request"
  },
  "jobrequest-search-page": {
    "no-requests": "There are currently no hidden job requests."
  },
  "jobrequests-page": {
    "tab-archived": "Archived",
    "tab-drafts": "Drafts",
    "tab-favourite": "Favourites",
    "tab-hidden": "Hidden",
    "tab-new": "Requests",
    "tab-proposed": "Proposed",
    "title": "Job requests",
    "title-archived-proposals": "Archived Proposals",
    "title-archived-proposed": "Archived proposals",
    "title-drafts": "Drafts",
    "title-proposed": "Proposed"
  },
  "legal-form": {
    "bv": "Private limited company",
    "eenmanzaak": "Sole proprietorship",
    "other": "Other",
    "vennootschap": "Commercial partnership"
  },
  "modal-delegate-screening": {
    "label-email": "Email address",
    "label-firstname": "Given name",
    "label-lastname": "Surname",
    "label-prefix": "Prefix (optional)"
  },
  "modal-supplier-company-details-send": {
    "body": "The company details are sent to us for approval. Are you sure you want to continue?",
    "header": "You are about to send your company information to Brainnet",
    "title": "Send company details"
  },
  "navigate": {
    "toassignment": "View assignment"
  },
  "navigation": {
    "assignments": "Assignments",
    "contracts": "Contracts",
    "employments": "Employments",
    "home": "Home",
    "jobrequests": "Job requests",
    "overviews": "Overviews",
    "overviews-candidates": "Candidates",
    "overviews-contracts": "Contracts",
    "overviews-costs": "Expense report",
    "overviews-hours": "Timesheets",
    "overviews-hours-and-expenses": "Hours and expenses",
    "overviews-invoices": "Invoices",
    "overviews-jobrequests": "Jobrequests",
    "overviews-paymentproposals": "Payment proposals",
    "reports": "Overviews",
    "reports-candidates": "Candidates",
    "reports-contracts": "Contracts",
    "reports-costs": "Expense report",
    "reports-hours": "Timesheets",
    "reports-invoices": "Invoices",
    "reports-jobrequests": "Jobrequests",
    "reports-paymentproposals": "Payment proposals",
    "admin": "Admin"
  },
  "onboarding": {
    "slides": {
      "contracts": {
        "header": "Contracts",
        "subheader-1": "Clear contracts",
        "subheader-2": "Coverage of liability risks",
        "text-1": "Here, you can view contracts and sign these directly online if you’re an authorised signatory.",
        "text-2": "Only those who do nothing never make a mistake. Through Magnit you can have the professional and corporate liability risk covered."
      },
      "declare-expenses": {
        "header": "Declare expenses",
        "subheader-1": "Declare expenses",
        "subheader-2": "Expense report",
        "text-1": "You can declare all your expenses or travel costs here. Once you have submitted your expenses, your hiring manager can review these.",
        "text-2": "This report gives you insight into all your declared and approved expenses or travel costs. In some cases it is necessary to download and send this report."
      },
      "delegated-screenings": {
        "header": "Screenings",
        "subheader": "Your Pre Employment Screening",
        "text": "In preparation of your assignment, we ask you to provide all the necessary information for the screening."
      },
      "intro": {
        "header": "What’s new?",
        "subheader": "New features!",
        "text": "There are new features available in the portal. Click ‘next’ to learn more."
      },
      "invoices": {
        "header": "Invoices",
        "subheader-1": "Guaranteed payments",
        "subheader-2": "Early payments",
        "text-1": "Here, you can view the invoices and their payment dates. If applicable, you can approve the payment proposal.",
        "text-2": "Do you want to receive your money earlier than the regular payment term? We offer the possibility to pay your invoice faster."
      },
      "jobrequests": {
        "header": "Job requests",
        "subheader-1": "Job requests",
        "subheader-2": "Propose candidate",
        "text-1": "You will find an overview of all interesting vacancies and assignments under job requests. Depending on previously selected expertises, you will only see the vacancies and assignments applicable to you.",
        "text-2": "You can propose yourself or other candidates for interesting vacancies or assignments. You can tailor your CV and motivation specifically to each assignment. We select the best candidates for our clients."
      },
      "manage-jobrequests": {
        "header": "Manage job requests",
        "subheader": "Expertises",
        "text": "You can always add or change previously selected expertises for you and the rest of your organisation."
      },
      "plus-package": {
        "header": "<span class='plus-sign-xl'>+&hairsp;</span> Assignment and <span class='plus-sign-xl'>+&hairsp;</span> package",
        "subheader": "<span class='plus-sign-lg'>+&hairsp;</span> Assignment",
        "text": "Some assignments are marked with a <b class='plus-sign'>+&hairsp;</b> icon. These are so-called <b class='plus-sign'>+&hairsp;</b> assignments. A <b class='plus-sign'>+&hairsp;</b> assignment is an assignment that always includes a <b class='plus-sign'>+&hairsp;</b> package. A <b class='plus-sign'>+&hairsp;</b> package comprises:<br><br><ul class='brainnet-ul'><li class='bullit-point'>Early payment within 15 days</li><li class='bullit-point'>Standard cover for professional liability and corporate liability risks</li></ul>The <b class='plus-sign'>+&hairsp;</b> package costs €2.50 per invoiced hour and includes Magnit services. Magnit will send you an invoice for this amount and set it off against the payment you receive from Magnit."
      },
      "register-worked-hours": {
        "header": "Register worked hours",
        "subheader-1": "Hours worked",
        "subheader-2": "Timesheet",
        "text-1": "Here you can register all your worked hours. Once you have submitted your worked hours, your hiring manager can assess them.",
        "text-2": "This report gives you insight into all your worked and approved hours. In some cases it is necessary to download and send this report."
      },
      "screenings": {
        "header": "Screenings",
        "subheader-1": "Pre Employment Screening",
        "subheader-2": "Delegate the screening to the Expert",
        "text-1": "Before the Expert can be deployed, a Pre Employment Screening is carried out. We ask you to provide all the necessary information.",
        "text-2": "It is now also possible to delegate the screening to the Expert. He or she can log into the portal and provide all the necessary documentation and information."
      }
    }
  },
  "options": {
    "nationality": "Nationality"
  },
  "page": {
    "subpage": {
      "screening-id-verification": {
        "documentnumber": "Document number"
      }
    }
  },
  "password": {
    "alert-changed": "The password is changed.",
    "alert-not-changed": "The password has <ins>not</ins> been changed, please try again.",
    "change-instruction": "Change your password here.",
    "change-instruction-must-differ": "The password must be different from previous passwords and must meet the following conditions:",
    "did-not-validate": "The password does not meet all conditions",
    "enter-current-password": "Enter the current password",
    "enter-new-password": "Enter the new password",
    "has-digits": "Contains at least one digit",
    "has-lowercase-characters": "Contains at least one lowercase letter",
    "has-minimum-amount-of-characters": "Consists of at least 12 characters",
    "has-symbols": "Contains at least one special character",
    "has-uppercase-characters": "Contains at least one capital letter",
    "hide-password": "Hide password",
    "label-current-password": "Current password",
    "label-new-password": "New password",
    "label-repeat-new-password": "Repeat new password",
    "not-enough-characters": "The password must contain at least 12 characters",
    "not-possible": "The chosen password is not possible",
    "previous-password": "It is not possible to use a previously used password",
    "repeated-password-does-not-match": "The passwords do not match",
    "repeat-new-password": "Repeat the new password",
    "same-as-current": "Password is the same as the current password",
    "show-password": "Show password",
    "strength-good": "Good",
    "strength-very-strong": "Very strong",
    "strength-weak": "Weak",
    "wrong-password": "The password is incorrect."
  },
  "payment-proposal": {
    "amount-to-blocked-bankaccountnumber": "Amount deposited into G-account",
    "amount-to-this-account": "Amount deposited into this account",
    "approval-failed": "The payment proposal has not been approved. Please try again.",
    "approval-succeeded": "The payment proposal is approved.",
    "approve": "Approve payment proposal",
    "bankaccountnumber": "Your bank account number",
    "blocked-bankaccountnumber": "G-account number",
    "blocked-by-credit-invoice-error": "It is not possible to approve this payment proposal. Please approve the credit payment proposals first. <a href='overviews/payment-proposals'>Go to the payment proposal overview</a>.",
    "blocked-by-credit-payment-proposal-error": "It is not possible to approve this payment proposal. Please approve the credit payment proposals first. <a href='supplier/overviews/payment-proposals'>Go to the payment proposal overview</a>.",
    "check-details": "Check the payment details. In case the payment details are incorrect, please contact financial administration at Magnit.",
    "choose-payment-option": "Choose your payment option:",
    "credit-proposal-exists": "Please approve all credit invoice proposals before approving debit invoice proposals",
    "details": "Payment proposal details",
    "details-are-correct": "The payment details are correct",
    "download": "Download payment proposal",
    "early-payment": "Early payment",
    "error-while-validating-invoice-number": "Something went wrong. Invoice number could not be validated.",
    "invoice-number": "Invoice number",
    "invoice-type": "Invoice type",
    "non-unique-invoice-number-error": "This invoice number was used previously, provide another number.",
    "no-records": "There are no payment proposals yet.",
    "payment-details": "Payment details",
    "payment-option": "Payment within {{days}} days with <b>{{percentage}}% payment discount</b>",
    "payment-proposals": "Payment proposals",
    "percentage-to-blocked-bankaccountnumber": "Percentage to G-account",
    "provide-invoice-number": "Enter a unique invoice number (max. 22 characters)",
    "search-gave-no-records": "There are currently no payment proposals that meet these criteria.",
    "payment-option-1": "Payment within {{days}} working day with <b>{{percentage}}% payment discount</b>"
  },
  "plus-package": {
    "header": "+ assignment",
    "subheader": "+ assignment",
    "text": "A <b class='plus-sign'>+&hairsp;</b><b>assignment</b> is an assignment which always includes a <b class='plus-sign'>+&hairsp;</b><b>package</b>. <br/> A <b class='plus-sign'>+&hairsp;</b><b>package</b> comprises: <br/> <ul><li>Early payment within 15 days</li> <li>Standard cover for professional liability and business liability risks</li></ul> <br/> The <b class='plus-sign'>+&hairsp;</b><b>package</b> costs €2.50 per invoiced hour and includes Magnit services. Magnit will send you an invoice for this amount and set it off against the payment you receive from Magnit."
  },
  "privacy-statement": {
    "accept": "I agree",
    "header": "PRIVACY STATEMENT MAGNIT",
    "instruction": "To use the portal you must agree with the privacy conditions.",
    "reject": "I do not agree"
  },
  "end-user-license-agreement": {
    "accept": "I agree",
    "header": "END USER LICENSE AGREEMENT",
    "instruction": "To use the portal you must agree with the end user license agreement.",
    "reject": "I do not agree"
  },
  "professionalMutationDetails": {
    "address1": "Address 1",
    "address2": "Address 2",
    "address3": "Address 3",
    "address-title": "Address",
    "city": "City",
    "contact-title": "Contact details",
    "country-title": "Country",
    "dateOfBirth": "Date of birth",
    "employment-type-title": "Employment type",
    "empoyment-type-title": "Employment type",
    "firstname": "Given name",
    "firstNames": "First names",
    "fullname": "Full name",
    "gender": "Gender",
    "initials": "Prefix",
    "lastName": "Surname",
    "nationality": "Nationality",
    "personal-details-title": "Personal details",
    "placeOfBirth": "City of birth",
    "preferredLastName": "Prefered last name for business email address",
    "private-email-private-title": "Private email address",
    "readonly": "Status Expert details",
    "status-professional-details": "Status Expert details",
    "telephone-number": "Telephone number",
    "zipCode": "Postal code"
  },
  "profile-contact": {
    "contact-brainnet": "Contact Magnit",
    "contact-brainnet-text": "Do you have any questions, or can we be of service to you in any way? Contact our Magnit Global Services & Support Center for further information, chat with a virtual or live agent, or submit a support ticket via:",
    "contact-brainnet-text-2": "The Magnit Services & Support Center is available in English and also in Dutch. To help you to navigate our Support Center, please see the videos on the bottom of the page.",
    "important-text": "<span class='font-bold'>Important: </span>When creating the ticket, in the *System dropdown select the option Magnit VMS (Netherlands).",
    "contact-magnit": "Contact Magnit",
    "dutch-chamber-of-commerce-number": "Dutch Chamber of Commerce number",
    "normal-open-hours": "9 a.m. to 4 p.m.",
    "openinghours": "Opening hours visiting address",
    "postal-address": "Postal address",
    "public-holidays-notice": "* Opening hours on public holidays may vary.",
    "service-now": {
      "overview-video": "<span class='font-bold'>Overview video: </span>In this video you will get an overview of the Services & Support Center, including how to log in, chat with a virtual or live agent, submit a support ticket and much more. When creating the ticket, in the *System dropdown select the option Magnit VMS (Netherlands). Please click the image below to go to the video.",
      "registration-video": "<span class='font-bold'>Registration video: </span>In this video we will walk you through how to register an account in our Services & Support Center. We recommend that you have a user account for full access to our self-help tools. Please click the image below to go to the video. <span class='font-bold'>Please note that the below video will be in Dutch.</span>",
      "text": "The Magnit Services & Support Center provides a modernized interface that assists users to Find Answers, Chat with a Virtual or Live Agent, Open a Case, Track the Status of a Case, or Check System Status.",
      "title": "The Magnit Services & Support Center"
    },
    "other-info": {
      "tab-title": "Other contact information",
      "title": "Other Contact details Page",
      "text": "For all your support inquires we recommend you to contact our Global Support Team through the Magnit Global Services and Support center using the link provided on the page above.",
      "text-2": "However, if you need assistance, you can also reach us by phone or email via the below contact details:",
      "phone-number": "<a href='tel:+31306021617'>+31 (0) 30 602 16 17</a> (maandag t/m vrijdag, van 9.00 tot 16.00 uur)"
    },
    "technical-difficulties-with-telephone-service": "We are currently experiencing technical difficulties with our telephone service. However, you can reach us by e-mail.",
    "vat-number": "VAT number",
    "visiting-address": "Visiting address"
  },
  "profile-details-summary": {
    "active-workorders": "Active work orders",
    "business-organisation": "Business organisation",
    "registered-since": "Registered since",
    "view-job-requests": "View job requests"
  },
  "profile-edit-email": {
    "cancel": "Cancel",
    "cannot-edit-modal": {
      "text": "Unfortunately, it is not possible to change the e-mail address to the e-mail address that was just entered. Please contact Magnit.",
      "title": "Email address cannot be changed"
    },
    "current-password": "Current password",
    "header": "Change e-mail address",
    "modal": {
      "change": "Change",
      "text": "Are you sure you want to change your e-mail address? From now on you can only sign in with this e-mail address.",
      "title": "Change e-mail address"
    },
    "new-email": "New e-mail adress",
    "new-email-again": "Repeat new e-mail address",
    "save": "Save",
    "save-error": "The email address has <u>not</u> been changed.",
    "save-success": "The email address has been changed.",
    "subheader": "Explanation",
    "text": "The email address is necessary to access the portal. We will also use this email address to inform you if needed."
  },
  "profile-edit-language": {
    "cancel": "Cancel",
    "dropdown-title": "Language",
    "header": "Change language setting",
    "save": "Save",
    "save-error": "The language has <u>not</u> been changed.",
    "save-success": "The language is changed.",
    "subheader": "Explanation",
    "text": "Change your language setting here. From now on, the information will be displayed in the selected language."
  },
  "profile-edit-notifications": {
    "cancel": "Cancel",
    "header": "Change notification preferences job requests",
    "no-option": "No, don’t send me notifications",
    "save": "Save",
    "save-error": "The notification preferences for job requests have <u>not</u> been changed.",
    "save-success": "The notification preferences for job requests are changed.",
    "subheader": "Explanation",
    "text": "Here you can specify whether you wish to receive notifications about new job requests in your areas of expertise. You can also specify whether you want to receive the e-mail notifications or not.",
    "title": "I would like to receive the newest job requests via e-mail:",
    "yes-option": "Yes, send me notifications when new job requests are available"
  },
  "profile-edit-password": {
    "changed": "The password is changed.",
    "header": "Change password",
    "not-changed": "The password has not been changed, please try again.",
    "password-confirmation": {
      "confirm": "Change password",
      "subheader": "Are you sure?",
      "text": "You are about to change your password. When you do, you are automatically logged out and are required to log in again using your new password.",
      "title": "Change password"
    },
    "subheader": "Explanation"
  },
  "profile-expertises": {
    "at-least-one-warning": "At least 1 expertise must be selected.",
    "header": "Explanation expertises",
    "modal": {
      "change": "Change expertises",
      "text-part1": "Are you sure?",
      "text-part2": "You are about to change the expertises for everyone at",
      "title": "Change expertises"
    },
    "text": "You can use this form to change expertises. These areas of expertise are necessary in order to be able to see the specific job requests and to be able to propose candidates to these job requests. Do more people within your organisation have a portal account? Please note that the changes made also apply to their accounts.",
    "updated-successful": "The expertises has been changed."
  },
  "profile-faq": {
    "assignments": {
      "answer-1": "When you started at the client but the assignment status says it hasn't started yet, it means that the contract for the assignment has not been added.",
      "answer-10": "AMP will contact you through an sms or e-mail. This will include a link to their website. You can choose a suitable time and location for the ID check on their website.",
      "answer-11": "Magnit works with the AMP group to perform the ID checks on location.",
      "answer-12": "You can choose in the portal whether you want to carry out the ID check digitally, at Magnit office in Nieuwegein, or at a location of your choice. The digital ID check can be performed immediately. If you select a location of your choosing, AMP will contact you to make an appointment for the ID check. If you select the office of Magnit, you choose a suitable date and time to come by at the office. This choice will be confirmed via e-mail.",
      "answer-13": "Only those who do nothing never make a mistake. By covering the professional and corporate liability risk, you are insured if such mistakes occur.",
      "answer-14": "Please contact the Client Services Team if you were unable to complete the digital ID check, for example because your phone or laptop crashed. You can contact the Client Services Team using the contact information on the assignment.",
      "answer-2": "The client communicates the extention of the assignment to us. At the moment, the assignment has ended in the portal, because the client has not yet communicated the extension to us. The extention also needs to be processed. This can take up to 2 days.",
      "answer-3": "A thorough screening is necessary to check whether professionals actually have the correct qualifications, papers, diplomas, and verified references, etc. Checking these prevents clients from being faced with surprises.",
      "answer-4": "A screening can be shared with an expert. This means the expert can enter and upload the details themselves.",
      "answer-5": "For some screenings it is necessary to complete all previous screening items before you can complete the next.",
      "answer-6": "Please contact the Client Services Team using the contact information on the assignment.",
      "answer-7": "Please contact the Client Services Team using the contact information on the assignment.",
      "answer-8": "When you can view the screening but can't complete it, the screening is assigned to another coworker in your organisation.",
      "answer-9": "After you selected the option in the screening to have your ID checked at a location of your choosing, AMP will contact you. This will be done through an sms or e-mail.",
      "header": "Assignments",
      "question-1": "The assignment status says it hasn't started yet. But I've already started with the assignment. How is this possible?",
      "question-10": "How can I make an appointment with AMP?",
      "question-11": "What is AMP?",
      "question-12": "How can I get my ID checked?",
      "question-13": "Why do I need professional and corporate liability insurance?",
      "question-14": "I have not been able to complete the digital ID check. What should I do?",
      "question-2": "The assignment status says that it has ended, but the assignment has been extended. How is this possible?",
      "question-3": "Why is a screening necessary?",
      "question-4": "What is: share with expert?",
      "question-5": "I can't complete all screening items. Why not?",
      "question-6": "I have a question about a screening. What should I do?",
      "question-7": "I have a question about a document. What should I do?",
      "question-8": "I can view the screening but I can't complete it. How is this possible?",
      "question-9": "My ID has to be checked. When will AMP contact me?"
    },
    "assignments-text": "Below you will find all frequently asked questions about assignments, screenings and the professional and corporate liability insurance through Magnit.",
    "contracts": {
      "answer-1": "When you can view your contract but can't sign it, you are not the authorised signatory. Please contact us via the contact information on the assignment.",
      "answer-2": "Please contact the Client Services Team via the contact details on the assignment.",
      "answer-3": "Please contact the Client Services Team via the contact details on the assignment.",
      "answer-4": "When the screening is completed, the contract will be drawn up. This can take up to 5 days. ",
      "answer-5": "The client communicates the extension of your contract. After this, it will take up to 2 days to process. ",
      "answer-6": "The extension of the contract is communicated by the client. You don't have to do anything. ",
      "answer-7": "Only the authorised signatory can approve the contract. Please contact us via the contact details on the assignment.",
      "answer-8": "Only contracts that were signed online are visible in the portal. Contracts that are sent by e-mail are not visible here. ",
      "header": "Contracts",
      "question-1": "I can view the contract, but can't sign it. Why is this?",
      "question-2": "I don't agree with the contract. What should I do?",
      "question-3": "There is a mistake in the contract. What should I do?",
      "question-4": "The screening is completed. Why didn't I receive my contract?",
      "question-5": "My contract is extended, but I can't see it in the portal. What should I do?",
      "question-6": "How can I communicate the extension of my contract?",
      "question-7": "Somebody has to approve the contract. How can I communicate this?",
      "question-8": "I can't see every contract in the portal. How is this possible?"
    },
    "contracts-text": "Below you will find all frequently asked questions about viewing and signing contracts.",
    "hours": {
      "answer-1": "If you can't submit your hours, it's possible your contract hasn't been processed yet. Are you sure it has been? Please contact the Client Services Team using the contact information on the assignment.",
      "answer-10": "Not everyone can claim their expenses. Is this something you agreed to with your hiring manager? Please contact the Client Services Team using the contact information on the assignment.",
      "answer-11": "It is not possible to submit hours that were worked over 6 months ago. ",
      "answer-12": "If you cannot submit your hours, your contract has ended and we haven't received a contract extension. Has your contract been extended? Please contact the Client Services Team using the contact information on the assignment.",
      "answer-2": "If you can't submit your expense claims, it's possible your contract hasn't been processed yet. Are you sure it has been? Please contact the Client Services Team using the contact information on the assignment.",
      "answer-3": "When the hours haven't been approved yet, you can choose 'withdraw'. Are the hours already approved? Please contact your manager and discuss if you can submit the hours in another week. ",
      "answer-4": "When the expense claims haven't been approved yet, you can choose 'withdraw'. Are the expense claims already approved? Please contact your manager. They can cancel the expense claims. ",
      "answer-5": "Please contact your hiring manager. If you two cannot come to a solution, you can contact the Client Services Team using the contact information on the assignment.",
      "answer-6": "The reason your hours were rejected is stated in the notification. You can adjust the hours and submit them again. ",
      "answer-7": "Please contact your hiring manager. If you two cannot come to a solution, you can contact the Client Services Team using the contact information on the assignment.",
      "answer-8": "Not everyone can claim kilometers. Is this something you agreed to with your hiring manager? Please contact the Client Services Team using the contact information on the assignment.",
      "answer-9": "Not everyone can claim their travel expenses. Is this something you agreed to with your hiring manager? Please contact the Client Services Team using the contact information on the assignment.",
      "header": "Hours and expenses",
      "question-1": "I can't submit my hours. Why not?",
      "question-10": "I can't claim my expenses. Why not?",
      "question-11": "I want to submit hours in the past but I'm unable to. Why not?",
      "question-12": "I can't submit my hours. Why not? ",
      "question-2": "I can't submit my expense claims. Why not?",
      "question-3": "I submitted the wrong hours. What should I do?",
      "question-4": "I submitted the wrong expense claims. What should I do?",
      "question-5": "I submitted my hours, but they haven't been approved. What should I do? ",
      "question-6": "I submitted my hours, but they got rejected. What should I do?",
      "question-7": "The cost center to which I should write my hours is not available. What should I do?",
      "question-8": "I can't claim my kilometers. Why not?",
      "question-9": "I can't claim my travel expenses. Why not?"
    },
    "hours-text": "Below you will find all frequently asked questions about registering worked hours, timesheets, declaring expenses and expense reports.",
    "insurance": {
      "answer-1": "Via Alicia. Contact Alicia by phone at <A HREF='tel:+31 85-0004100'>+31 85-0004100</A> and by email at <A HREF='mailto:magnit@alicia.insure'>magnit@alicia.insure</A>, and an expert at Alicia will then inform you about what happens next.",
      "answer-2": "Alicia is an insurtech (digital insurer) on a mission. Too many freelancers go to work uninsured. Alicia's here to solve this problem by integrating fitting, flexible insurance into online platforms, such as Magnit. Find out more on <A HREF='https://alicia.insure/' target='_blank'>alicia.insure</A>.",
      "header": "Liability insurance via Alicia",
      "question-1": "Where do I file a claim?",
      "question-2": "Who is Alicia?",
      "text": "Below you will find all frequently asked questions about the professional and corporate liability insurance we provide via Alicia."
    },
    "invoices": {
      "answer-1": "Please contact our Global Support team via <A HREF='https://support.magnitglobal.com/help' target='_blank'>https://support.magnitglobal.com/help</A>.<br><b>Important:</b> When creating the ticket, in the *System dropdown select the option Magnit VMS (Netherlands).<br><br>For assistance on how to use the Magnit Services and Support Center, <A HREF='https://www.youtube.com/watch?v=_khC2MP8RPc' target='_blank'>click here</A>",
      "answer-10": "Magnit works according to the invoicing requirements of the government. For more information, <A HREF='https://www.belastingdienst.nl/wps/wcm/connect/bldcontentnl/belastingdienst/zakelijk/btw/administratie_bijhouden/facturen_maken/factuureisen/factuureisen' target='_blank'>click here</A>.",
      "answer-11": "Payment proposals are approved by one or more designated individuals within your organization with the rights to do so. When you want to make some changes within these rights, you can contact the Client Services Team via the contact details on the assignment.",
      "answer-12": "Please contact our Global Support team via <A HREF='https://support.magnitglobal.com/help' target='_blank'>https://support.magnitglobal.com/help</A> or via <A HREF='mailto:auditorsstatements@magnitglobal.com'>auditorsstatements@magnitglobal.com</A>.<br><b>Important:</b> When creating a ticket, in the *System dropdown select the option Magnit VMS (Netherlands).<br><br>For assistance on how to use the Magnit Services and Support Center, <A HREF='https://www.youtube.com/watch?v=_khC2MP8RPc' target='_blank'>click here</A>",
      "answer-13": "When you send your invoice, you will receive confirmation within 7 days. When you didn't receive the confirmation within the stated days, you can contact our Global Support team via <A HREF='https://support.magnitglobal.com/help' target='_blank'>https://support.magnitglobal.com/help</A>.<br><b>Important:</b> When creating the ticket, in the *System dropdown select the option Magnit VMS (Netherlands).<br><br>For assistance on how to use the Magnit Services and Support Center, <A HREF='https://www.youtube.com/watch?v=_khC2MP8RPc' target='_blank'>click here</A>",
      "answer-2": "Please contact our Global Support team via <A HREF='https://support.magnitglobal.com/help' target='_blank'>https://support.magnitglobal.com/help</A>.<br><b>Important:</b> When creating the ticket, in the *System dropdown select the option Magnit VMS (Netherlands).<br><br>For assistance on how to use the Magnit Services and Support Center, <A HREF='https://www.youtube.com/watch?v=_khC2MP8RPc' target='_blank'>click here</A>",
      "answer-3": "The reason your invoice was denied is stated in the notification of the denial. You can adjust the invoice and submit it again. ",
      "answer-4": "Sometimes you want to receive your money a little bit earlier. If you give a certain discount (dependant on when you want to receive your money) you can receive your payment faster. ",
      "answer-5": "You can ask for an earlier payment during the approval of the payment proposal. If there is not a payment proposal applicable in your situation, you can contact our Global Support team via <A HREF='https://support.magnitglobal.com/help' target='_blank'>https://support.magnitglobal.com/help</A>. Please mention early payment in the subject line of the Support Request.<br><b>Important:</b> When creating the ticket, in the *System dropdown select the option Magnit VMS (Netherlands).<br><br>For assistance on how to use the Magnit Services and Support Center, <A HREF='https://www.youtube.com/watch?v=_khC2MP8RPc' target='_blank'>click here</A>",
      "answer-6": "Before you can approve the payment proposal you have to approve the credit invoice because of the settlement.",
      "answer-7": "The payment can be delayed because of multiple reasons. The contract hasn't been signed or the tax risk hedging isn't in order. Please contact our Global Support team via <A HREF='https://support.magnitglobal.com/help' target='_blank'>https://support.magnitglobal.com/help</A>.<br><b>Important:</b> When creating the ticket, in the *System dropdown select the option Magnit VMS (Netherlands).<br><br>For assistance on how to use the Magnit Services and Support Center, <A HREF='https://www.youtube.com/watch?v=_khC2MP8RPc' target='_blank'>click here</A>",
      "answer-8": "The invoice will be paid on the date noted on the invoices. When the payment wasn't received on this date, either the contract or tax risk hedging is missing. ",
      "answer-9": "Please send your invoice to <A HREF='mailto:invoicesNL@magnitglobal.com'>invoicesNL@magnitglobal.com</A>.",
      "header": "Invoices and payment proposals",
      "question-1": "There is a mistake in my payment proposal. What should I do?",
      "question-10": "What should be on my invoice?",
      "question-11": "Who can approve the payment proposals?",
      "question-12": "I have some questions about the tax risk cover. Who can I contact about this?",
      "question-13": "I send an invoice but didn't receive a confirmation. What should I do?",
      "question-2": "There is a mistake in my invoice. What should I do?",
      "question-3": "My invoice was denied. What should I do?",
      "question-4": "What is early payment?",
      "question-5": "I would like to use early payment. How can I use this?",
      "question-6": "I want to approve a payment proposal but I get the notification to approve the credit invoice first. Why?",
      "question-7": "The payment date has passed, but I didn't receive my payment. What should I do?",
      "question-8": "When will my invoice be paid?",
      "question-9": "Where do I send my invoice?"
    },
    "invoices-text": "Below you will find all frequently asked questions about invoices, invoice instructions, payment proposals and early payments.",
    "job-requests": {
      "answer-1": "To propose a candidate, go to 'Job requests' and select the correct job request. Here you can click 'Propose candidate'. ",
      "answer-10": "You have to change your expertises in 'Profile'. Click 'Expertises'. Is this option not available? Please contact us using our contact information. Go to 'Support' at the top right and click on 'Contact'.",
      "answer-11": "Go to 'Profile' and to 'Settings'. Select 'E-mail notifications new job requests'. Or contact us using our contact information. Go to 'Support' at the top right and click on 'Contact'.",
      "answer-12": "We tend to respond as quick as possible. When we receive more information from our client, we will let you know as soon as possible. ",
      "answer-13": "Unfortunately, it is not possible to propose a candidate after the deadline has passed. ",
      "answer-14": "If you disagree with a rejection, you can contact the Client Services Team using the contact information on the job request.",
      "answer-15": "On the top left, go to 'Job requests' and click on 'Proposed candidates'. Here you can see an overview of the proposed candidates. ",
      "answer-16": "Sometimes additional information is needed to propose the candidate in the client's system. In these cases, we request this additional information when you propose a candidate.",
      "answer-17": "You can withdraw your candidate from the job request by clicking 'withdraw'. This is only possible when the candidate hasn't been proposed yet. If you want to withdraw a candidate after he/she was proposed, please contact the Client Services Team using the contact information on the job request.",
      "answer-2": "You can add a candidate under 'Overviews'. Here you click 'Candidates' and 'Add candidate'. You can also add a candidate when you are in the process of proposing a candidate. Click on 'Add new candidate' to add the candidate. ",
      "answer-3": "You can delete a candidate under 'Overviews'. Here you click 'Candidates' and then the trashcan icon. Note: if a candidate is proposed to a job request, the candidate can't be deleted until the candidate is rejected. ",
      "answer-4": "Go to 'Overviews' and to 'Candidates'. Click the pencil next to the candidate you want to edit. Note: if a candidate is proposed to a job request, the candidate can't be edited until the candidate is rejected. ",
      "answer-5": "There are a few possible reasons why you can't find a request. The deadline may have passed, the request was moved to your favorites or the request was hidden. It is also possible that the expertises of the request were changed. ",
      "answer-6": "First check if you selected the right expertises. Go to 'Profile' and click 'Expertises'. When you selected the right expertises, you don't have the required rights. Please contact us using our contact information. Go to 'Support' at the top right and click on 'Contact'. ",
      "answer-7": "You can select the star next to a request to move it to your favorites. ",
      "answer-8": "Next to every job request is the symbol of an eye. Select this to hide the request. ",
      "answer-9": "Click on 'hidden' to view all hidden requests. Click on the eye next to the request to make it visible again. Please note: this is only possible if the deadline hasn't passed yet.",
      "header": "Job Requests",
      "question-1": "How can I propose a candidate?",
      "question-10": "I'm interested in other expertises. How can I change these? ",
      "question-11": "Where can I apply to receive job requests per e-mail?",
      "question-12": "I proposed a candidate. When can I expect to hear something?",
      "question-13": "The deadline passed but I still want to propose a candidate. How can I do this?",
      "question-14": "My candidate got rejected for an application but I disagree with the reasoning behind this. What can I do?",
      "question-15": "How can I see if my proposed candidate has been received?",
      "question-16": "Why do I have to provide additional information about my candidate for certain job requests? ",
      "question-17": "I added a candidate but I want to withdraw the candidate. How can I do this?",
      "question-2": "How can I add a candidate? ",
      "question-3": "How can I delete a candidate?",
      "question-4": "How can I edit the details of a candidate?",
      "question-5": "What can I do if I can no longer find the job request? ",
      "question-6": "What can I do when I don't have the rights for a specific job request?",
      "question-7": "How can I move a job request to my favorites?",
      "question-8": "How can I hide a job request?",
      "question-9": "I've hidden a job request. How can I make it visible again?"
    },
    "job-requests-text": "Below you will find all frequently asked questions about job requests, proposing candidates and managing candidates.",
    "plus-package": {
      "answer-1": "A <b class='plus-sign'>+&hairsp;</b> assignment is an assignment that always includes a  <b class='plus-sign'>+&hairsp;</b> package.<br>The <b class='plus-sign'>+&hairsp;</b> package comprises the following:<br><br><ul class='brainnet-ul'><li class='bullit-point'>Early payment: within 15 days of receipt of invoice</li><li class='bullit-point'>Standard cover for professional liability and corporate liability risk</li></ul>It costs €2.50 per invoiced hour and includes Magnit services.",
      "answer-2": "A <b class='plus-sign'>+&hairsp;</b> assignment always includes the <b class='plus-sign'>+&hairsp;</b> package. The <b class='plus-sign'>+&hairsp;</b> package comprises early payment within 15 days after you submit your invoice, cover for your professional liability risk and corporate liability risk, and Magnit services. A <b class='plus-sign'>+&hairsp;</b> assignment means that the client in question has chosen to invest their search fees in the supply chain.",
      "answer-3": "Yes, certainly. You don't pay for your registration with Magnit and you can respond to assignments free of charge. You may respond to any assignment. Only <b class='plus-sign'>+&hairsp;</b> assignments incur charges. Naturally, it's up to you to decide whether you respond to these assignments.",
      "answer-4": "If you take on a <b class='plus-sign'>+&hairsp;</b> assignment, your professional and corporate liability risk is automatically covered. You are also assured of receiving payment within 15 days of submitting your invoice. Unfortunately, you cannot have one without the other.",
      "header": "<b class='plus-sign-lg'>+&hairsp;</b> Assignments and <b class='plus-sign-lg'>+&hairsp;</b> package",
      "question-1": "What is a <b class='plus-sign'>+&hairsp;</b> assignment?",
      "question-2": "Why do I need to pay for the <b class='plus-sign'>+&hairsp;</b> assignment?",
      "question-3": "Is registration with Magnit still free?",
      "question-4": "Professional liability and corporate liability risk cover is included in a <b class='plus-sign'>+&hairsp;</b> package. I don't need it/I already have cover. Can I remove it?",
      "tab-title": "<b class='plus-sign'>+&hairsp;</b> Assignments and <b class='plus-sign'>+&hairsp;</b> package",
      "text": "Below you will find all frequently asked questions about <b class='plus-sign'>+&hairsp;</b> assignments and the <b class='plus-sign'>+&hairsp;</b> package."
    },
    "search": "Search",
    "search-header": "Frequently asked questions",
    "search-text": "Here you will find the most frequently asked questions divided by subject. You can also search using the search bar.",
    "search-textbox": "Start searching now",
    "settings": {
      "answer-1": "In the upper right corner, go to 'Profile' and click on 'settings'. Here you find 'change password'. ",
      "answer-2": "In the upper right corner, go to 'Profile' and click on 'settings'. Here you find 'Change notification preferences job requests' where you can change your preferences. ",
      "answer-3": "Please contact us using our general contact information. Go to 'Support' in the upper right corner and click on 'Contact'.",
      "answer-4": "Marconibaan 1A, 3439 MR Nieuwegein. ",
      "answer-5": "In the upper right corner, go to 'Profile' and click on 'settings'. Here you find 'change e-mail address'. ",
      "header": "Settings",
      "question-1": "How can I change my password? ",
      "question-2": "How can I stop the job request notifications? ",
      "question-3": "My question isn't mentioned in this FAQ. How can I contact you?",
      "question-4": "What's the address of Magnit?",
      "question-5": "I want to change my e-mail address. What should I do? "
    },
    "settings-text": "Below you will find all frequently asked questions about changing your settings, such as your password or e-mail address."
  },
  "profile-overview": {
    "tab-company-details": "Company details",
    "tab-contact": "Contact",
    "tab-details": "Company details",
    "tab-expertises": "Expertises",
    "tab-faq": "FAQ",
    "tab-overview": "Overview",
    "tab-settings": "Settings"
  },
  "profile-overview-default": {
    "contact-subtitle": "Contact us if you have any questions",
    "contact-title": "Contact",
    "expertises-subtitle": "Select the expertises for which you want to see job requests",
    "expertises-title": "Expertises",
    "faq-subtitle": "Find answers to frequently asked questions",
    "faq-title": "FAQ",
    "overview-company-details": "Company details",
    "overview-company-details-subtitle": "Overview of your registered details",
    "settings-subtitle": "Change your account settings",
    "settings-title": "Settings"
  },
  "profile-profesional": {
    "anwsers": "Frequently Asked Questions",
    "personal-data": "Personal information",
    "personal-data-overview": "Overview of your registered information",
    "profession": "Expertises",
    "questions": "Frequently Asked Questions"
  },
  "profile-settings": {
    "account-email-text": "Change your e-mail address:",
    "account-language-header": "Language",
    "account-language-text": "Change your language setting",
    "account-modify": "Edit",
    "account-notifications-header": "E-mail notifications new job requests",
    "account-notifications-text": "Change your notification settings",
    "account-password-header": "Password",
    "account-password-text": "Change your password",
    "privacy-and-cookie-statement": "Privacy and cookie statement",
    "privacy-confermation": {
      "conferm": "Withdraw consent",
      "subheader": "Are you sure?",
      "text": "You are about to withdraw your consent to the privacy statement. If you do not agree, you will not be able to use the portal and you will be logged out automatically. Click on cancel to keep your consent.",
      "title": "Withdraw consent"
    },
    "privacy-cookie-link": "https://www.brainnet.nl/en/cookie-statement/",
    "privacy-cookiestatement": "Cookie statement",
    "privacy-privacy-link": "https://www.brainnet.nl/en/privacy-statement/",
    "privacy-privacystatement": "Privacy statement",
    "privacy-subheader": "The privacy statement and cookie statement",
    "privacy-text": "By clicking the following link you have a complete insight into the privacy statement and the cookie statement of Magnit.",
    "privacy-waiver-part1": "You have given your consent to the privacy statement of Magnit on",
    "privacy-waiver-part2": ".",
    "privacy-withdraw-consent": "Withdraw consent"
  },
  "profile-supplier": {
    "add-extra-tradename": "Add trade name",
    "basic": {
      "addition": "Addition",
      "business-address": "Business address",
      "city": "City",
      "company-form": "Company form",
      "company-form-organisation": "Organisation supplies Experts",
      "company-form-self-employed": "Self-employed Expert",
      "company-name": "Company name",
      "country": "Country",
      "legal-form": "Legal form",
      "number": "Number",
      "postal-address": "Postal address",
      "postal-code": "Postal code",
      "postal-same-as": "Same as business address",
      "registered-name": "Statutory company name",
      "street": "Street",
      "telephone-email": "Email and phone",
      "telephone-number": "General phone number",
      "title": "Company data",
      "trade-names": "Trade names"
    },
    "coc": {
      "certificate": "Upload CoC extract",
      "certificate-text": "Please upload all applicable Chamber of Commerce extracts here.<br>An extract from the CoC cannot be older than 3 months.",
      "country": "Country of establishment",
      "number": "CoC number",
      "registered-at": "Registered with the CoC in",
      "title": "Dutch Chamber of Commerce registration (CoC)"
    },
    "coc-number-error": "The provided CoC number is incorrect",
    "coc-registration-end-date-in-past": "The company related to this CoC number is no longer registered at the Dutch Chamber of Commerce.",
    "company-details-submitted": "Thank you! The company details have been sent to Magnit.",
    "contact": {
      "contact-main-can-sign": "The contact person is authorised to sign",
      "contact-main-is-financial": "The contact person is responsible for the financial administration",
      "contact-person": "Contact person",
      "email-address": "Email address",
      "financial": "Financial administration",
      "firstname": "Given name",
      "lastname": "Surname",
      "lastname-according-to-coc": "Surname (in accordance with the CoC extract)",
      "main": "(Main) Contact person",
      "position": "Position",
      "prefix": "Prefix (optional)",
      "signer": "Authorised signatory according to CoC",
      "telephone-email": "Email address and phone number",
      "telephone-number": "Phone number",
      "title": "Contact persons and authorised signatories"
    },
    "contact-add": "Add a contact person",
    "control": {
      "send": "Send",
      "title": "Verify and send"
    },
    "draft-saved": "The information was successfully saved as a draft",
    "financial": {
      "bank-account": "Bank account number",
      "gaccount": "G-account number",
      "gaccount-certificate": "Copy G-account registration",
      "iban": "(IBAN) Bank account number",
      "payroll-taxes": "Payroll taxes number",
      "title": "Financial details",
      "vat-number": "VAT number"
    },
    "information-sent": "Your company details have been sent to Magnit",
    "needs-information": "You cannot send the company details yet. Not all required fields have been filled in.",
    "standards": {
      "certificate": "SNA certificate",
      "intermediaries-act-text": "The ‘Placement of Personnel by Intermediaries Act’ (Waadi) states that companies that provide workers on a paid basis must state this explicitly. If your company provides workers on a paid basis, your registration in the Dutch Commercial Register must include this statement. Please indicate below whether your company has the Waadi registration and whether the provision of workers is done commercially or non-commercially.",
      "intermediaries-act-title": "Intermediaries Act",
      "intermediaries-registered": "Registered for Posting of Workers by Intermediaries Act?",
      "intermediaries-registered-purpose": "Purpose",
      "intermediaries-registered-purpose-company": "Commercially",
      "intermediaries-registered-purpose-non-company": "Non-commercially",
      "labour-standards-registered": "Labour standards registered?",
      "labour-standards-text": "When a company is registered in the Labour Standards Register (Register Normering Arbeid), it offers companies who hire personnel greater protection against fraud and illegality. If your company is registered in the Labour Standards Register, only 25% of the invoice amount is deposited into the G-account, instead of 55%. You can upload your registration below.",
      "labour-standards-title": "Labour Standards registration",
      "title": "Intermediaries Act and Labour Standards"
    },
    "status": {
      "complete": "Company details complete",
      "incomplete": "Company details incomplete",
      "rejected": "Company details rejected",
      "waiting-for-approval": "Company details in review"
    },
    "status-page": {
      "change-company-details": "Change company details",
      "date-received": "Date received",
      "date-reviewed": "Date reviewed",
      "header": "Status",
      "provided-by": "Provided by",
      "provided-information": "Provided information",
      "reason-for-rejection": "Reason for rejection",
      "rejected": "Rejected",
      "waiting-for-review": "Waiting for review"
    },
    "summary": {
      "basic-title": "General information",
      "signer": "Authorised signatory",
      "telephone-number": "Phone number"
    },
    "tax-risk-mitigation": {
      "expert-flow-header": "An employee is made available as an expert",
      "expert-flow-text": "One of the following options can be selected:",
      "g-account": "G-account number",
      "g-account-certificate": "Copy G-account registration",
      "g-account-certificate-required": "A copy of the G-account registration is required.",
      "g-account-requested": "We have requested a G-account",
      "g-account-requested-additional-info": "<b>Important: </b>You can apply for a G account from the Belastingdienst. Please note that applying for the G account takes approximately 3-6 weeks. Magnit kindly requests that you apply for the G-account in a timely manner so that the payment of invoices is not delayed. As long as Magnit does not have your company's G-account number, the invoices cannot be paid.",
      "indemnity-declaration": "Indemnity declaration",
      "information-not-needed": "You have specified you will not provide any other experts than yourself. You do not have to provide this information.",
      "method-accountants-statement": "Our company is Waadi non-commercially registered and will send quarterly audit reports",
      "method-accountants-statement-disabled": "This option can only be chosen if your company is Waadi non-commercially registered. You can change this under the tab 'WAADI and SNA registration'.",
      "method-accountants-statement-download": "Please find an example here.",
      "method-accountants-statement-explanation": "The audit reports need to be sent at the end of each quarter. For this we use the Accountant's Statement model 3000A.",
      "method-g-account": "Deposit on the G-account",
      "method-g-account-insufficient": "Fill in the number of the G-account or select that it has been requested.",
      "method-indemnity-declaration": "No G-account deposit, because our company has an indemnity declaration",
      "model-service-agreement": "Model service agreement supplier",
      "model-service-agreement-confirmation": "The supplier confirms that the model service agreement is used for all its self-employed professionals",
      "model-service-agreement-download": "Please find an example here.",
      "self-employed-professional-flow-header": "A self-employed professional (zzp'er) is made available as an expert",
      "self-employed-professional-flow-text": "Magnit would like to receive the model service agreement that the supplier enters into with the self-employed professional. This concerns the template that is used. Instead of requesting the service agreement for each self-employed professional separately, Magnit requests the service agreement that the supplier uses for all its self-employed professionals.",
      "title": "Tax risk mitigation",
      "workforce-both": "Both an expert (employee) and a self-employed professional (entrepreneur)",
      "workforce-expert": "Expert (employee)",
      "workforce-self-employed-professional": "Self-employed professional (entrepreneur)",
      "workforce-text": "Our company provides the following experts:",
      "workforce-title": "Workforce"
    },
    "tooltip-g-account-number": "This only applies if your company is Waadi registered and the hiring is conducted on a commercial basis.",
    "tooltip-payroll-taxes": "This is only applicable when you employ workers.",
    "tooltip-vat-number": "Please enter the VAT number. If you have a sole proprietorship, enter the VAT identification number."
  },
  "proposaldraft-search-page": {
    "column-candidate": "Candidate and position",
    "column-deadline": "Deadline",
    "column-jobrequest": "Request number",
    "column-last-change": "Latest change",
    "column-options": "Options",
    "column-status": "Status"
  },
  "proposal-search-buttons": {
    "delete-part-one": "You are about to withdraw the proposal of",
    "delete-part-two": ". The proposal will be changed into a draft proposal. This can't be undone.",
    "proposal-has-been-withdrawn": "The proposal is withdrawn. You can find the proposal at <a href='/supplier/jobrequests/drafts'>drafts</a>.",
    "proposal-withdrawn": "The proposal is withdrawn. You can find the proposal at <a href='/jobrequests/drafts'>drafts</a>.",
    "we-will-withdraw-your-proposal": "We are going to withdraw your proposal."
  },
  "proposal-search-page": {
    "column-candidate": "Candidate and position",
    "column-deadline": "Deadline",
    "column-information": "Details",
    "column-jobrequest": "Request number",
    "column-last-change": "Latest change",
    "column-options": "Options",
    "column-status": "Status",
    "withdraw": "Withdraw",
    "withdraw-proposal": "Withdraw proposal",
    "withdraw-title": "Withdraw proposal"
  },
  "proposalstatus": {
    "active": "Received by Magnit",
    "concept": "Draft",
    "expired": "Expired",
    "inactive": "Rejected by Magnit",
    "inactive-closed": "Request closed",
    "intake": "Intake interview",
    "intake-rejected": "Rejected after intake interview",
    "placed": "Assignment started",
    "placement-to-follow": "Selected",
    "presented": "Introduced to client",
    "presented-rejected": "Rejected by client",
    "to-be-presented": "Introduced to client",
    "unspecified": "Pending"
  },
  "proposalstatus-information": {
    "active": "Magnit is assessing the candidate",
    "declinedafterintake": "The candidate is rejected by the client, after the intake interview",
    "inactive": "The candidate is rejected by Magnit",
    "inactivejobrequestclosed": "The request is no longer available",
    "intakeplanned": "The candidate is invited for an intake interview with the client, click on this row for the intake details",
    "notspecified": "The candidate is processed by Magnit",
    "onassignment": "The candidate will soon start working at the client.",
    "placementwillfollow": "The candidate is selected by the client. A Pre-Employment Screening will start",
    "proposaldeclined": "The candidate is rejected by the client",
    "proposed": "The candidate is introduced to the client",
    "unspecified": "The candidate is processed by Magnit",
    "waitingforproposal": "The candidate is introduced to the client"
  },
  "propose-candidate": {
    "deletedraft": {
      "body": "You are about to discard this draft proposal.<br>This can't be undone.",
      "title": "Delete draft"
    },
    "duplicatedraft": {
      "body": "A draft proposal for this job request and candidate already exists.<br>Do you want to see the draft proposal?",
      "confirm": "Go to draft",
      "title": "Draft proposal found"
    }
  },
  "propose-candidate-page": {
    "availableper": "Available from",
    "candidate-already-proposed": "You have already proposed the selected candidate on this job request. You can not propose a candidate twice on the same job request.",
    "candidate-already-proposed-button-text": "Go to proposal",
    "cvdownload": "CV",
    "employmentrelationship": "Employment relationship",
    "group-1": "Availability and rate",
    "group-2": "Employment relationship candidate",
    "group-3": "Level of education",
    "group-4": "Specific job requirements",
    "group-5": "Intermediaries",
    "group-6": "Motivation and CV",
    "group-7": "Temp-to-hire requirements",
    "highestlevelofeducation": "Highest qualification",
    "hourlyrate": "Rate per hour",
    "hoursperweek": "Hours per week",
    "intermediaries": "Company name (optional)",
    "jobrequest-is-no-longer-active": "The job request is no longer active. It is not possible to propose a candidate.",
    "margins": "Margin intermediary (optional)",
    "motivation": "Motivation text",
    "plannedvacation": "Planned holidays",
    "salaryIndication": "Indication of salary (gross monthtly income)",
    "salaryIndicationProposal": "Indication of Salary"
  },
  "screeningactions": {
    "afgerond": "Completed",
    "brainnet": "Magnit",
    "cancelled": "Cancelled",
    "complete": "Completed",
    "deskundige": "Expert",
    "geannuleerd": "Cancelled",
    "professional": "Expert",
    "supplier": "Supplier"
  },
  "screening-big-check-page": {
    "big-number": "BIG number",
    "header": "BIG registration",
    "title": "BIG registration"
  },
  "screening-document-upload-page": {
    "title": "Assignment"
  },
  "screening-id-verification": {
    "alert-saved-success": "Thank you! We’ll assess this as soon as possible."
  },
  "screening-id-verification-page": {
    "alert-saved-success": "Thank you! We’ll assess this as soon as possible.",
    "appointment-id-check": "Appointment identity check",
    "bsn": "Social security number",
    "date": "Date",
    "date-and-time": "Date and time",
    "date-of-issue": "Date of issue",
    "date-time-title": "At which date and time can the identification take place?",
    "document-details": "Document details",
    "documentnumber": "Document number",
    "documenttype": "Document type",
    "from": "from",
    "identity-card": "Identity card",
    "id-verification-not-applicable-text": "No ID check is required for this assignment.",
    "id-verification-not-applicable-title": "ID check not applicable",
    "location": "Location",
    "location-abroad": "Assignment outside the Netherlands",
    "location-abroad-text": "You indicated that an identity check in the Netherlands is not possible. Magnit will contact you about an alternative identity check.",
    "location-abroad-underlying-title": "Alternative Id check",
    "location-amp": "At a chosen location in the Netherlands",
    "location-amp-text": "You have chosen for an identity check on location. The AMP Group cooperates with Magnit to perform identity checks. The AMP Group will take up contact by SMS, or e-mail, with the information provided in 'Expert details'. The appointment for the identity check can be scheduled at a desired date/ time (Monday to Saturday - evenings are also possible).",
    "location-brainnet": "At Magnit in Nieuwegein",
    "location-brainnet-underlying-title": "Id check on location",
    "location-online": "Digital check",
    "location-online-delegate-text": "You have selected the digital ID check. The ID check will start after Step 3.",
    "location-online-recommended-label": "Recommended",
    "location-online-undelegate-text": "You have selected the digital ID check. After Step 3 an email will be sent to your candidate to perform the ID check. Please inform your candidate about this in advance.",
    "location-online-underlying-title": "Digital ID check",
    "passport": "Passport",
    "place-issued": "Place of issue",
    "select-id-type-identity-card": "Identity card",
    "select-id-type-passport": "Passport",
    "step-appointment": {
      "choose-location": "Select one of the following options for the identification",
      "explanation-eu": "Magnit is legally obligated to check the identity of an Expert through a valid passport or ID (not a driving licence).",
      "explanation-non-eu": "Magnit is legally obligated to check the validity of the work permit of the Expert. A valid passport or ID (not a driving licence) and the citizen service number are used for this check.",
      "title": "Schedule an appointment"
    },
    "step-check": {
      "text": "The following document details, provided during a previous screening, are still correct.",
      "title": "Check and send"
    },
    "step-document-details": {
      "details-earlier-screening-choice-no": "No, I want to edit the details",
      "details-earlier-screening-choice-text": "Are the above mentioned details still correct?",
      "details-earlier-screening-choice-yes": "Yes, the details are correct",
      "details-earlier-screening-text": "The following document details were provided during a previous screening.",
      "select-id-type-eu": "Select the document for identification. Identification is not possible with a driver's license.",
      "select-id-type-non-eu": "The identity is checked using the passport. Enter the requested details below.",
      "title": "Document details"
    },
    "step-four": {
      "explanation": "You have selected the digital ID check. To conduct the digital ID check, Magnit uses Rabobank Identity Services software. For the check, a photo of the identity document must be uploaded or taken with a webcam or the camera of a mobile phone. After that a short recording is made of the face. Based on the photos and recording, the identity of the Expert is verified. The photos and recording will not be saved.",
      "notes": "Please note - You will need the following to carry out the digital check:\n\n- A laptop/pc with webcam or a mobile phone/tablet with camera and at least iOS 10.3 or Android.\n- The identity document corresponding to the details entered in Step 1.\n",
      "start-button": "Start the digital ID check",
      "step-title": "Id Control",
      "title": "Digital ID check"
    },
    "step-one": {
      "choose-location": "Select one of the following options for the identification",
      "explanation-eu": "Magnit is legally obligated to check the identity of an Expert through a valid passport or ID (not a driving licence).",
      "explanation-non-eu": "Magnit is legally obligated to check the validity of the passport of the Expert. A valid passport and the citizen service number are used for this check.",
      "title": "Schedule an appointment"
    },
    "steps-title": "Schedule an appointment",
    "steps-title-readonly": "Read-only",
    "step-three": {
      "brainnet-address": "Marconibaan 1a",
      "brainnet-zipcode-and-city": "3439 MR Nieuwegein",
      "title": "Verify and send"
    },
    "step-two": {
      "select-id-type-eu": "Select the document for identification. Identification is not possible with a driver's license.",
      "select-id-type-non-eu": "The identity is checked using the passport. Enter the requested details below.1",
      "title": "Document details"
    },
    "time": "Time",
    "title": "Assignment",
    "undelegate-screening-alert-saved-success": "Thank you! We've send the e-mail to the expert.",
    "valid-until": "Date of expiry",
    "work-permit": "Work permit"
  },
  "screening-integrity-check": {
    "former-employers-added-success-alert": "Thank you! We will ask your former employers or clients to give their integrity statement.",
    "success-alert": "Thank you! We will send the Expert the integrity investigation as soon as possible."
  },
  "screening-integrity-check-page": {
    "add-employer": "Add at least 1 former employer or client.",
    "add-extra-reference": "Add additional employer or client",
    "comments": "Comments",
    "comments-explanation": "Are there any particularities we should take into account?",
    "company-name": "Company Name",
    "consent-text": "I hereby authorise Magnit to contact the aforementioned persons and ask them to assess my integrity.",
    "consent-title": "Consent for integrity investigation",
    "email": "Email address",
    "ex-employer-or-client": "Former employer or client",
    "explanation": "We carry out an integrity investigation for your future employer. Therefore, we will reach out to former employers and clients for an integrity statement. We ask you to give consent, so we can carry out the integrity investigation and share your data with former employers and clients. This way, they can sign the statement.",
    "name-contact": "Name contact person",
    "phone-number": "Phone number",
    "step-one": {
      "title": "Integrity check"
    },
    "steps-title": "Integrity investigation",
    "step-three": {
      "explanation": "Please check the provided details and give permission to approach the provided companies and persons to carry out an integrity investigation.",
      "title": "Verify and send"
    },
    "step-two": {
      "explanation": "Please provide the details of your former employers and clients that we can approach for the integrity investigation.",
      "title": "Add former employers or clients"
    }
  },
  "screening-integrity-check-send-page": {
    "explanation": "An integral part of the pre-employment screening is the integrity investigation, whereby we ask former employers/clients to issue an integrity statement regarding the Expert.<br />The Expert provides contact information for the former employers/clients. Magnit ask all the given former employers/clients via e-mail whether they want to issue an integrity statement.<br /><br />The request made to the Expert to provide contact information will be send to the following e-mail address:",
    "send-to-professional": "Send to Expert",
    "steps-title": "Integrity check",
    "title": "Explanation"
  },
  "screening-integrity-check-status-page": {
    "add-extra-employer": "Add extra former employer",
    "column-contact-details": "Contact details",
    "column-date-sent": "Request sent on",
    "column-employer": "Employer or client",
    "consent-explanation-professional": "On {{date}}, you gave permission to carry out an integrity investigation. We informed your former employers and clients about the integrity statement. We advise you to contact the provided former employers and clients as well, to ensure that the investigation can be completed as soon as possible.",
    "consent-explanation-supplier": "Here you will find the progress of the Expert regarding the integrity investigation. We've sent the e-mail to: {{email}}",
    "consent-explanation-supplier-delegated-screening": "Here you will find the progress of the Expert regarding the integrity investigation.",
    "former-employers-explanation": "Here you will find the former employers and clients which where provided. You can see per contact person whether they've issued an integrity statement.",
    "former-employer-status-email-sent": "E-mail sent",
    "former-employer-status-negative": "Negative integrity statement",
    "former-employer-status-page-visited": "Page visited",
    "former-employer-status-positive": "Positive integrity statement",
    "former-employer-status-refused": "Refused integrity statement",
    "former-employer-status-reminded": "Sent reminder e-mail",
    "header-consent": "Consent integrity investigation",
    "header-explanation": "Explanation",
    "header-provided-information": "Status Integrity investigation",
    "header-status-former-employers": "Status former employers or clients",
    "status-email-sent": "Email sent",
    "status-finished": "Finished",
    "status-page-edited": "Updated",
    "status-page-visited": "Page visited",
    "status-sent": "Sent",
    "title": "Status integrity investigation"
  },
  "screeningItem": {
    "big": {
      "readonly": "Status BIG registration"
    },
    "download": {
      "downloadable": "Downloadable document:"
    },
    "id-check": {
      "readonly": "Status Identity Check"
    },
    "integrity-check": {
      "readonly": "Status Integrity Investigation"
    }
  },
  "screening-item-status": {
    "action-needed": "Action required",
    "action-required": "Action required",
    "action-required-by-expert": "Action Required By Expert",
    "action-required-from-professional": "Action required by Expert",
    "completed": "Completed",
    "declined-action-required": "Rejected, action required",
    "declined-action-required-from-professional": "Rejected, action required by Expert",
    "waiting-for-integrity-statement": "Waiting for the integrity statement",
    "waiting-for-review-by-brainnet": "Being reviewed by Magnit"
  },
  "screening-liability-insurance": {
    "alicia-download-link": "detailed explanation of professional and corporate liability risk",
    "alicia-download-text-1": "Please read the ",
    "alicia-download-text-2": ". You can find more information in Alicia's platform.",
    "alicia-explanation": "For this part in the screening we work with a partner. By clicking the button below, you will be taken to Alicia's platform. There we will ask you to upload your insurance sheet to make sure your insurance has adequate coverage. If you are self-employed (zzp) and do not have the right insurance, you can join the collective professional and business liability insurance that Alicia has taken out as a company.\n\nPlease note: You are always responsible for your own insurance. If you, as an intermediary, provide a self-employed professional as an expert, you must upload your own insurance.",
    "consent-withholding-sentence": "I have read the explanation and accept the professional and corporate liability risk.",
    "i-have-my-own-insurance-sentence": "I have a professional and corporate liability insurance that covers the above mentioned amounts.",
    "insurance-through-brainnet": "I want to join the professional and corporate liability insurance of Magnit.",
    "insured-amount-corporate-liability": "Insured amount corporate liability:",
    "insured-amount-professional-liability": "Insured amount professional liability:",
    "please-state-how-you-cover-the-above-mentioned-risk": "Please state how you cover the above mentioned risk:",
    "proceed-to-alicia": "Proceed to Alicia",
    "submit-succeeded-message": "Thank you! We’ll assess this as soon as possible.",
    "the-client-demands-this-coverage": "For {{clientName}}, the professional and corporate liability insurance must cover the following amounts:",
    "the-following-polis-is-uploaded": "The following policy is uploaded",
    "upload-your-insurance-policy": "Upload your insurance policy",
    "you-have-insured-the-risk-through-brainnet": "You have insured above risk for this assignment through Magnit."
  },
  "screening-professional-details-edit-page": {
    "add-address": "Add extra address line",
    "addition": "Addition (optional)",
    "address": "Address",
    "address-title": "Address",
    "calling-name": "Given name",
    "city": "City",
    "complete-name": "Full name",
    "contact-title": "Contact details",
    "control-title": "Verify and send",
    "country": "Country",
    "date-of-birth": "Date of birth",
    "email-incorrect": "If the email address is incorrect, please contact Magnit.",
    "employment-relationship": "Employment relationship",
    "firstnames": "First names",
    "house-number": "House number",
    "initials": "Initials",
    "lastname": "Surname",
    "personal-title": "Personal details",
    "phone-number": "Phone number",
    "place-of-birth": "Place of birth",
    "postal-code": "Postal code",
    "preferred-name": "Surname used for business e-mail address (if different)",
    "preferred-name-tooltip": "When there are multiple surnames, you can choose to use a different name for the creation of an account (business e-mail address).",
    "prefix": "Prefix (optional)",
    "private-email": "Personal e-mail address",
    "professional-details-updated-success": "Thank you! The personal details of the Expert are delivered.",
    "street": "Street",
    "title": "Complete Expert details",
    "title-step-four": "Verify and send",
    "title-step-one": "Employment relationship",
    "title-step-three": "Contact details",
    "title-step-two": "Personal details"
  },
  "screeningremarks": {
    "contract-waiting-to-be-signed": "Contract waiting for signing",
    "do-many-actions": "Perform multiple actions",
    "do-screening-action": "Perform screening action",
    "payment-proposal-waiting-on-approval": "Proforma invoice waiting for approval",
    "wont-be-extended": "Won't be extended"
  },
  "screeningstatus": {
    "cancelled": "Cancelled",
    "completed": "Completed",
    "initiated": "Is initiated",
    "open": "Open",
    "rejected": "Rejected",
    "sufficient": "Sufficient for assignment"
  },
  "secondee-edit-page": {
    "professional-details-deleted-success": "The candidate is deleted.",
    "professional-details-updated-success": "Thank you. The canddidate details have been saved.",
    "step1-address": "Address",
    "step1-city": "City",
    "step1-country": "Country",
    "step1-dateofbirth": "Date of birth",
    "step1-default-text": "The following details are required if you want to propose a candidate for a job request.",
    "step1-given-name": "Given name",
    "step1-lastname": "Surname",
    "step1-personal-details-title": "Personal details",
    "step1-postalcode": "Postal code",
    "step1-prefix": "Prefix (optional)",
    "step1-title": "Candidate details",
    "step2-addition": "Addition (optional)",
    "step2-contact-details-title": "Contact details",
    "step2-default-text": "Some clients require the following information for the candidate proposal process. The information is also needed during the screening.",
    "step2-email": "Email address (optional)",
    "step2-firstnames": "First names (optional)",
    "step2-gender": "Gender (optional)",
    "step2-housenumber": "House number (optional)",
    "step2-initials": "Initials (optional)",
    "step2-nationality": "Nationality (optional)",
    "step2-personal-details-title": "Personal details",
    "step2-placeofbirth": "Place of birth (optional)",
    "step2-street": "Street (optional)",
    "step2-telephone": "Phone number (optional)",
    "step2-title": "Additional information",
    "step3-title": "Verify and send",
    "title": "Add candidate",
    "title-edit": "Edit candidate"
  },
  "secondee-search-buttons": {
    "cannot-edit-assigned-to-multiple-requests": "It is not possible to edit the candidate details of <b>{{fullName}}</b>, because the candidate is proposed to multiple job requests.",
    "cannot-edit-assigned-to-one-request": "It is not possible to edit the candidate details of <b>{{fullName}}</b>, because the candidate is proposed on <a href={{activeJobRequestId}}>this request</a>.",
    "cannot-edit-assigned-to-single-request": "It is not possible to edit the candidate details of <b>{{fullName}}</b>, because the candidate is proposed on <a href='jobrequests/{{activeJobRequestId}}/submitted-proposals'>this request</a>.",
    "cannot-edit-part-one": "It is not possible to edit the candidate details, because the candidate is proposed on a request.",
    "cannot-remove-assigned-to-multiple-requests": "It is not possible to remove <b>{{fullName}}</b>, because the candidate is proposed to multiple job requests.",
    "cannot-remove-assigned-to-one-request": "It is not possible to remove <b>{{fullName}}</b>, because the candidate is proposed on <a href={{activeJobRequest}}>this request</a>.",
    "cannot-remove-assigned-to-single-request": "It is not possible to remove <b>{{fullName}}</b>, because the candidate is proposed on <a href='jobrequests/{{activeJobRequestId}}/submitted-proposals'>this request</a>.",
    "delete-part-two": "You are about to remove [Given name] [Prefix] [Surname]. Are you sure? This action can't be undone.",
    "remove-candidate": "You are about to remove <b>{{fullName}}</b>. Are you sure? This action can't be undone."
  },
  "secondee-search-page": {
    "edit-title": "Candidate details not editable",
    "remove": "Remove candidate",
    "remove-secondee": "Remove",
    "remove-title": "Remove candidate"
  },
  "secondees-page": {
    "button-add": "Add candidate",
    "column-city": "City",
    "column-dateOfBirth": "Date of birth",
    "column-firstName": "Given name",
    "column-lastName": "Surname",
    "column-options": "Options",
    "column-postal-code": "Postal code",
    "empty-search": "There are no candidates that meet these criteria.",
    "search-gave-no-records": "No candidates have been added yet. Add a new candidate using the 'Add candidate' button.",
    "title": "Candidate overview"
  },
  "submitted-proposals-page": {
    "intake-details": {
      "header": "Intake details",
      "location": "Location",
      "meeting-partners": "Meeting partner(s)",
      "time": "Time"
    },
    "processing-text": "The candidate is being processed by Magnit",
    "proposalfailure": "The proposal of {{fullName}} has failed. Click <a href='/jobrequests/drafts/{{draftId}}'>here</a> to try again.",
    "proposal-failure": "The proposal of {{fullName}} has failed. Click <a href='/supplier/jobrequests/drafts/{{draftId}}'>here</a> to try again.",
    "status-being-processed": "Pending"
  },
  "support": {
    "additional-services": "Additional Services",
    "additional-services-part": {
      "subtitle1": "Additional services",
      "text1": "At Magnit we want you to be able to do business without any worries. It is therefore possible to have your professional and business liability risk covered through us. You can also get paid extra quickly. This way you can mainly focus on your assignment, we will take care of the rest."
    },
    "additional-services-subtitle": "Information about Additional services and Plus",
    "fast-payment": {
      "subtitle1": "Early payment",
      "text1": "Magnit uses the payment terms of the client. If you want to receive your money faster, it is usually possible to use the option 'early payment'. Depending on the preferred earlier payment term, you apply a discount to your invoice total. The exact payment term differs per client and is indicated in the portal. You have the option to choose early payment for an assignment as a whole. In case the option for early payment isn't used for an assignment, it is possible to use the option on an invoice basis."
    },
    "insurance": {
      "about-alicia": {
        "subsection-text": "You can reach Alicia by phone at <A HREF='tel:+31 85-0004100'>+31 85-0004100</A> and by email at <A HREF='mailto:magnit@alicia.insure'>magnit@alicia.insure</A>. You can reach Alicia on working days, between 09:00 and 17:00.",
        "subsection-title": "Alicia is here for you",
        "text": "Alicia is an insurtech (digital insurer) on a mission. Too many freelancers go to work uninsured. Alicia's here to solve this problem by integrating fitting, flexible insurance into online platforms, such as Magnit. Find out more on <A HREF='https://alicia.insure/' target='_blank'>alicia.insure</A>.",
        "title": "About Alicia"
      },
      "claims": {
        "subsection-text": "You can do so by contacting Alicia by phone at <A HREF='tel:+31 85-0004100'>+31 85-0004100</A> and by email at <A HREF='mailto:magnit@alicia.insure'>magnit@alicia.insure</A>. An expert at Alicia will then discuss the next steps.",
        "subsection-title": "Need to file a claim?",
        "title": "Claims"
      },
      "coverage": {
        "covered-text": "<ul class='brainnet-ul'><li class='bullit-point'>Damages to people</li> <li class='bullit-point'>Damages to other people's belongings</li> <li class='bullit-point'>Damages due to a professional error, like a calculation error or wrong advice</li> <li class='bullit-point'>Damages caused by an intern or temporary worker</li> <li class='bullit-point'>Assistance and costs if you're held liable</li></ul>",
        "covered-title": "What's covered?",
        "insured-sum-business-liability": "For business liability:<br><ul class='brainnet-ul'><li class='bullit-point'>In case of damage to a person or other people's property, the insured sum is €2,500,000 per claim, with a maximum of €10,000,000 per year for all certificate holders combined</li> <li class='bullit-point'>The deductible is €500 per claim for other people's property</li></ul>",
        "insured-sum-professional-indemnity": "For professional indemnity:<br><ul class='brainnet-ul'><li class='bullit-point'>For professional errors, the insured sum is €1,000,000 per claim, with a maximum of €5,000,000 per year for all certificate holders combined</li> <li class='bullit-point'>The deductible is €2,500 per claim</li></ul>",
        "insured-sum-title": "What's the insured sum?",
        "not-covered-text": "<ul class='brainnet-ul'><li class='bullit-point'>Other people's belonging you work with, borrow or rent</li> <li class='bullit-point'>Personal belongings</li> <li class='bullit-point'>Fraud and other crimes</li> <li class='bullit-point'>Motor vehicles</li> <li class='bullit-point'>Intent</li> <li class='bullit-point'>Sexual behaviour</li> <li class='bullit-point'>Damages due to investment advice or asset management</li> <li class='bullit-point'>Damages due to not respecting intellectual property rules</li> <li class='bullit-point'>Damages due to environmental pollution </li> <li class='bullit-point'>Damages due to intentional wrongdoing</li> <li class='bullit-point'>Damages due to illegal activities, like fraud</li> <li class='bullit-point'>Outsourcing of work</li></ul>",
        "not-covered-title": "What isn't covered?",
        "professional-liability-text": "Covers your business liability and professional indemnity. So you are protected in case of calculation errors, wrong advice, if you damage someone's belongings or someone gets hurt because of you.",
        "professional-liability-title": "Professional liability",
        "terms-and-conditions": "Want a complete overview of what is and isn't covered? Check the terms & conditions. <A HREF='https://aliciabenefits.com/magnit-eng/' target='_blank'>View the policy conditions via this link</A>.",
        "text": "When you take out insurance with Alicia as a self-employed person, you are covered as follows.",
        "title": "Coverage"
      },
      "introduction": {
        "title": "Introduction",
        "text": "All work involves errors at some point or another. This is common knowledge and is not necessarily a problem in and of itself. If people working via Magnit cause damage at the hiring party, this hiring party can hold Magnit liable for the consequential damage.<br><br>Contractually, Magnit can recover this damage from the party that caused the damage, i.e. the Supplier. Such a claim could have drastic consequences for the Supplier's financial position or even result in bankruptcy.<br><br>As this is an undesirable situation, Magnit works with the company Alicia. They check if your insurance is appropriate for the work you will be doing.<br><br>In addition, Alicia offers freelancers working through Magnit the opportunity to purchase appropriate insurance. More information about this insurance can be found <A HREF='https://aliciabenefits.com/magnit-eng/' target='_blank'>on Alicia's website</A>."
      },
      "subtitle": "Information about professional liability insurance",
      "title": "Insurance"
    },
    "premium-suppliers": {
      "menu-title": "<span class='plus-sign'>+&hairsp;</span> Assignments",
      "subtitle1": "<b>What is a <span class='plus-sign'>+&hairsp;</span> assignment?</b>",
      "subtitle2": "<b>Standard cover for professional liability and corporate liability risks</b>",
      "subtitle3": "<b>Early payment</b>",
      "text1": "A <b class='plus-sign'>+&hairsp;</b> assignment is an assignment that always includes a <b class='plus-sign'>+&hairsp;</b> package.<br>The <b class='plus-sign'>+&hairsp;</b> package comprises the following:<br><br><ul class='brainnet-ul'><li class='bullit-point'>Early payment within 15 days</li><li class='bullit-point'>Standard cover for professional liability and corporate liability risks</li></ul>This package costs €2.50 per invoiced hour and includes Magnit services. Magnit will send you an invoice for this amount and set it off against the payment you receive from Magnit.",
      "text2": "Professional liability is liability for professional errors in your work or work done by your employees.<br>Corporate liability is liability for material damage or personal injury caused by you or one of your employees. Professional liability risk and corporate liability risk for the assignment in question is covered through Magnit's insurance if you take on a <b class='plus-sign'>+&hairsp;</b> assignment.",
      "text3": "For a <b class='plus-sign'>+&hairsp;</b> assignment, invoices are paid within 15 days of receipt of the invoice. If you would like to get paid more quickly, early payment is possible. Depending on the preferred earlier payment term, you apply a discount to your invoice total. The exact payment term differs per client and is indicated in the portal. You have the option to choose early payment per invoice.",
      "title": "<span class='plus-sign-lg'>+&hairsp;</span> Assignments"
    },
    "professional-and-business-liability-risks": {
      "button-more-info": "More information",
      "subtitle1": "Professional and corporate liability coverage",
      "text1": "Professional liability is liability for professional errors in your work or work done by your employees. Corporate liability is liability for material damage or personal injury caused by you or one of your employees."
    },
    "support": "Support"
  },
  "test": "test",
  "textarea-field": {
    "characters": "characters"
  },
  "validation": {
    "required": "Is required"
  },
  "forgotPasswordHeader": "Forgot password",
  "forgotPasswordText": "Did you lose or forget your password? Please fill out your email address and we will send an email to create a new password as soon as possible.",
  "emailPlaceholder": "Email address",
  "invalidEmail": "The email address is invalid, please enter a valid email address.",
  "emptyEmail": "This field is required",
  "sendButton": "Send",
  "emailSentHeader": "The e-mail has been sent",
  "emailSentText": "The email to create a new password has been sent to the specified email address if the email address is known to us.",
  "notReceivedHeader": "Didn't receive an email?",
  "notReceivedText": "If you have not received an email within a few minutes, please check your junk email or spam inbox.<br><br>It is also possible that the email address does not exist within our administration. Also check the spelling of the email address carefully."
}
